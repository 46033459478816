import PikaImg from '1_shared/assets/images/pika.gif';
import ThankYouVideo from '1_shared/assets/video/dothearts_thankyou.mp4';

import 'app/styles/global/global.scss';
import './hero-success.scss';

const HeroSuccess = () => (
  <section className="hero-success">
    <div className="container">
      <div className="hero-success__inner">
        <img
          src={PikaImg}
          alt="Оплата прошла успешно, спасибо!"
          className="hero-success__image"
        />
        <h1 className="hero-success__title section-title-big">
          Оплата прошла успешно, спасибо!
        </h1>
        <div className="hero-success__subtitle">
          Вы стали на шаг ближе к ответам на ваши вопросы
        </div>
      </div>
    </div>
    <div className="video-wrap">
      <video
        src={ThankYouVideo}
        className="video-item"
        width="100%"
        height="auto"
        playsInline
        muted
        loop
        autoPlay
        preload="auto"
      />
    </div>
  </section>
);

export default HeroSuccess;
