import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { RoutePath } from '1_shared/config/routes';
import { InfoSpecialist, SpecSpecialization } from '2_entities';
import { PreviewImage, SpecialistNavigate } from '3_features';

import { kindConfig } from '../../../1_shared/config/kindConfig';
import { Button, Chips, Typography } from '../../../1_shared/ui';
import SpecDescription from '../../../2_entities/SpecDescription/ui/SpecDescription';

import { configMedia } from './config/configMedia';
import { ISpecialistCardProps } from './interfaces/ISpecialistCardProps';
import { ISpecialistTimeForm } from './interfaces/ISpecialistTimeForm';

import styles from './SpecialistCard.module.scss';

const SpecialistCard = ({
  spec,
  dark = false,
  slicedBlock = false,
  className,
  showDescription = false,
}: ISpecialistCardProps): React.ReactElement => {
  const { control, handleSubmit } = useForm<ISpecialistTimeForm>();
  const navigate = useNavigate();
  const onSubmit = (data: ISpecialistTimeForm) => {
    // @ts-ignore
    ym(97788822, 'reachGoal', 'booking');
    navigate(RoutePath.ORDER, {
      state: { id: spec.id, slot: data.slot || spec.slots[0] },
    });
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleToggleDescription = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    setShowFullDescription(prev => !prev);
  };

  const shortDescription =
    spec.description && spec.description.length > 450
      ? `${spec.description.slice(0, 450)}...`
      : spec.description || '';

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      key={spec.id}
      className={cn(
        styles.root,
        { [styles.rootDark]: dark },
        slicedBlock ? styles.sliced : null,
        className,
      )}
    >
      <Controller
        control={control}
        name="slot"
        render={({ field: { value } }) => (
          <div className={styles.wrapperForm}>
            <PreviewImage
              className={styles.wrapperImage}
              media={spec.mediaContentResponse ?? configMedia}
            />
            <div className={styles.nameBlock}>
              <Typography
                type="title"
                className={cn({ [styles.nameBlockDark]: dark })}
              >
                {spec.firstName}
              </Typography>
              <Typography
                type="title"
                className={cn({ [styles.nameBlockDark]: dark })}
              >
                {spec.secondName}
              </Typography>
            </div>
            <InfoSpecialist
              dark={dark}
              classname={styles.wrapperInfo}
              specialist={spec}
              value={value}
            />
            <div className={styles.aboutSpec}>
              {!!spec.specialities.length && (
                <SpecSpecialization
                  dark={dark}
                  className={styles.wrapperSpec}
                  specialization={spec.specialities}
                />
              )}
              {showDescription && spec.description && (
                <div className={styles.showDescription}>
                  <SpecDescription
                    description={
                      showFullDescription ? spec.description : shortDescription
                    }
                  />
                  {spec.description.length > 450 && (
                    <Button
                      type="secondary"
                      onClick={handleToggleDescription}
                      className={styles.toggleButton}
                      icon={
                        <DownOutlined
                          className={cn(styles.toggleIcon, {
                            [styles.iconRotated]: showFullDescription,
                          })}
                        />
                      }
                    />
                  )}
                </div>
              )}
            </div>
            {value?.kind && (
              <div className={styles.infoSpecBloc}>
                <Typography type="description">ТИП СЕССИИ</Typography>
                <Chips
                  className={cn({
                    [styles.darkChips]: dark,
                  })}
                >
                  <Typography
                    type="p"
                    className={cn(styles.textChips, {
                      [styles.nameBlockDark]: dark,
                    })}
                  >
                    {kindConfig[value.kind]}
                  </Typography>
                </Chips>
              </div>
            )}
            <SpecialistNavigate
              className={styles.wrapperBtns}
              id={spec.id}
              dark={dark}
              slots={spec.slots}
            />
          </div>
        )}
      />
    </form>
  );
};

export default SpecialistCard;
