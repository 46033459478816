import variables from 'app/styles/variables.module.scss';

/**
 * TS переменные для scss цветов
 */
interface Variables {
  readonly [key: string]: string;
  readonly primaryBtnColor: string;
  readonly primaryBtnHoverColor: string;
}

export default variables as Variables;
