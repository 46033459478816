import { PropsWithChildren } from 'react';
import cn from 'classnames';

import Close from '../../../assets/pictures/closeWhite.svg';

import { IChipsProps } from './intrefaces/IChipsProps';

import styles from './Chips.module.scss';

const ChipsAction = (props: PropsWithChildren<IChipsProps>) => {
  const { className, children, onClick, onDelete, active = false } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      {...props}
      className={cn(className, styles.root, { [styles.active]: active })}
    >
      <div className={cn(styles.content, { [styles.activeContent]: active })}>
        {children}
        {active && (
          <button className={styles.imgButton} type="button" onClick={onDelete}>
            <img src={Close} alt="close" className={styles.imgClose} />
          </button>
        )}
      </div>
    </button>
  );
};

export default ChipsAction;
