import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AuthProvider from './AuthProvider';
import FilterProvider from './FilterProvider';
import Routes from './Routes';
import ScrollToHashElement from './ScrollToHashElement';
import ScrollToTop from './ScrollToTop';

const PrivateRouter = (): React.ReactElement => (
    <BrowserRouter>
      <AuthProvider>
        <FilterProvider>
          <ScrollToHashElement>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </ScrollToHashElement>
        </FilterProvider>
      </AuthProvider>
    </BrowserRouter>
  );

export default PrivateRouter;
