export const phoneConvert = (phone: string) =>
  phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4-$5');

export const phoneFormConvert = (phone: string) => {
  const tempPhone = phone.replaceAll(/[()\s-]/g, '').slice(0, 12);
  if (
    tempPhone.includes('(') &&
    tempPhone.includes('+') &&
    tempPhone.includes('-')
  ) {
    return tempPhone
      .replace(/(\+\d)(\(\d{3}\))(\d{3}-)(\d{2}-)(\d{2})/, '+7 $2 $3 $4 $5')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '');
  }

  if (tempPhone.charAt(0) !== '+') {
    return tempPhone.replace(
      /(\d)(\d{3})(\d{3})(\d{2})(\d{2})/,
      '+7 $2 $3 $4 $5',
    );
  }

  return tempPhone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '7 $2 $3 $4 $5');
};
export const uzPhoneFormConvert = (phone: string) => {
  const tempPhone = phone.replaceAll(/[()\s-]/g, '').slice(0, 13);
  if (
    tempPhone.includes('(') &&
    tempPhone.includes('+') &&
    tempPhone.includes('-')
  ) {
    return tempPhone
      .replace(/(\+\d{3})(\d-{2}-)(\d{7})/, '+998 $2 $3')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '');
  }

  if (tempPhone.charAt(0) !== '+') {
    return tempPhone.replace(
      /(\d{3})(\d{2})(\d{7})/,
      '+998 $2 $3',
    );
  }

  return tempPhone.replace(/(\d{3})(\d{2})(\d{7})/, '998 $2 $3');
};