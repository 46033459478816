export const getParamsUtm = (params: { [x: string]: string }) =>
  Object.fromEntries(
    Object.entries(params)?.filter(([key]) => key.includes('utm')),
  );

export const getParamsWithoutUtm = (params: { [x: string]: string } = {}) =>
  Object.entries(params)?.filter(([key]) => !key.includes('utm'));

export const createLocationString = (params: any[]) =>
  params?.map(el => [el?.[0], el?.[1]].join('='))?.join('&');
