import { ReactElement } from 'react';

import { declination, yearTitles } from '1_shared/helpers/declination';
import { Breadcrumbs, Typography } from '1_shared/ui';
import { Slots, SpecialistReserveSlot } from '3_features';

import { eduConfig } from '../../../1_shared/config/eduConfig';
import { format } from '../../../1_shared/config/format';

import { ISpecialistEntitiesCardProps } from './interfaces/ISpecialistEntitiesCardProps';

import styles from './SpecialistPersonCardData.module.scss';

const SpecialistPersonCardData = ({
  specialist,
  value,
  onChange,
}: ISpecialistEntitiesCardProps): ReactElement => {
  const breadCrumbs = [
    {
      path: 'https://psychologi.uz/',
      title: 'Psychologi Uz',
    },
    {
      path: '/specialists',
      title: 'КАЖДОМУ',
    },
    {
      path: '/specialists/1',
      title: 'СПЕЦИАЛИСТЫ ДЛЯ МЕНЯ',
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.nameAndSpec}>
        <Breadcrumbs routes={breadCrumbs} />
        <div className={styles.specialistPersonInfo}>
          <div className={styles.nameBlock}>
            <Typography className={styles.firstName} type="title">
              {specialist.firstName}
            </Typography>
            <Typography className={styles.secondName} type="title">
              {specialist.secondName}
            </Typography>
          </div>
          <Slots
            slots={specialist.slots}
            spec={specialist}
            onChange={onChange}
            value={value}
          />
        </div>

        <div className={styles.specFirst}>
          <div className={styles.specLineItem}>
            <Typography type="description">ФОРМАТ</Typography>
            <Typography>{format[specialist.format]}</Typography>
          </div>
          <div className={styles.specLineItem}>
            <Typography type="description">ОПЫТ</Typography>
            <Typography>{`${specialist.experience} ${declination(Number(specialist.experience), yearTitles)}`}</Typography>
          </div>

        </div>
        <div className={styles.specSecond}>
        <div className={styles.specLineItem}>
            <Typography type="description">СПЕЦИАЛИЗАЦИЯ</Typography>
            <div>
              {specialist.specialities?.map((spec, index) => (
                <Typography key={spec}>
                  {`${spec}${index !== specialist.specialities.length - 1 ? ', ' : '.'}`}
                </Typography>
              ))}
            </div>
          </div>
          <div className={styles.specLineItem}>
            <Typography type="description">ОБРАЗОВАНИЕ</Typography>
            <Typography>{eduConfig[specialist.educationType]}</Typography>
          </div>
        </div>
      </div>
      <SpecialistReserveSlot
        slot={value}
        specialistId={specialist.id}
        spec={specialist}
      />
    </div>
  );
};

export default SpecialistPersonCardData;
