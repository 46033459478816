import React from 'react';
import InputMask from 'react-input-mask';
import { Flex } from 'antd';
import cn from 'classnames';

import { Label } from '../../Label/index';

import { IMaskInput } from './interfaces/IMaskInput';

import styles from './MaskInput.module.scss';

const MaskInput = ({
  label,
  name = '',
  mask,
  onChange,
  onFocus,
  value,
  disabled,
  suffix,
  onBlur,
  className,
  placeholder,
}: IMaskInput): React.ReactElement => (
  <Flex vertical>
    {label && <Label name={name} label={label} />}
    <Flex>
      <InputMask
        onChange={onChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        mask={mask}
        name={name}
        value={value}
        placeholder={placeholder}
        className={cn(styles.innerInput, className, {
          [styles.disabled]: disabled,
          [styles.borderSuffix]: suffix,
        })}
      />
      {suffix && suffix}
    </Flex>
  </Flex>
);

export default MaskInput;
