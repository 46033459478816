import { useCallback, useEffect,useState } from 'react';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import { specApiInstance } from '1_shared/api/apiInstance';
import { ISpecialistEventData } from '1_shared/config/interfaces/ISpecialistEventData';

import { getActiveWeek } from '../utils/getActiveWeek';

import { IEventsSchedule } from './interfaces/IEventsSchedule';
import { ISpecialistSlots } from './interfaces/ISpecialistSlots';

const useGetSchedule = () => {
  const [schedule, setSchedule] = useState<IEventsSchedule[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeWeek, setActiveWeek] = useState<Date>(getActiveWeek(dayjs()));
  const [selectedSpecialist, setSelectedSpecialist] = useState<string | null>(null);
  const [specialists, setSpecialists] = useState<ISpecialistEventData[]>([]);

  const getEvents = useCallback(async () => {

    if (!selectedSpecialist) return;

    setIsLoading(true);
    const events: AxiosResponse<ISpecialistSlots> = await specApiInstance.get(
      `/ss/slots/v1/${selectedSpecialist}`,
      {
        params: {
          from: dayjs(activeWeek).format('YYYY-MM-DD'),
          to: dayjs(activeWeek).add(6, 'days').format('YYYY-MM-DD'),
          zoneOffset: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    );

    if (events.data) {
      const eventsOnSchedule = Object.values(events.data).flatMap(event => [
        ...(event?.avalibleSlots.map(event => ({
          id: event.slotId,
          title: 'Свободный',
          start: dayjs(event.start).toDate(),
          end: dayjs(event.end).toDate(),
        })) || []),
        ...(event?.orderedSlots.map(event => ({
          id: event.slotId,
          title: event.clientName || 'Аноним',
          start: dayjs(event.start).toDate(),
          end: dayjs(event.end).toDate(),
        })) || []),
      ]);
      setSchedule(eventsOnSchedule);
      setIsLoading(false);
    }
  }, [activeWeek, selectedSpecialist]);

  const fetchSpecialists = useCallback(async () => {
    const response: AxiosResponse<ISpecialistEventData[]> = await specApiInstance.get(
      '/spec/specialist/all',
    );

    if (response.data) {
      setSpecialists(response.data);
    }
  }, []);

  useEffect(() => {
    if (selectedSpecialist) {
      getEvents();
    }
  }, [activeWeek, getEvents, selectedSpecialist]);

  useEffect(() => {
    fetchSpecialists();
  }, []);

  const onNext = () => {
    setActiveWeek(dayjs(activeWeek).add(1, 'week').toDate());
  };

  const onPrev = () => {
    setActiveWeek(dayjs(activeWeek).subtract(1, 'week').toDate());
  };

  const fetchEvents = () => {
    getEvents();
  };

  const deleteEvent = (slotId: string) => {
    setSchedule(prev => prev.filter(e => e.id !== slotId));
  };

  return {
    schedule,
    activeWeek,
    isLoading,
    onNext,
    onPrev,
    fetchEvents,
    deleteEvent,
    selectedSpecialist,
    setSelectedSpecialist,
    specialists,
    fetchSpecialists,
  };
};

export default useGetSchedule;
