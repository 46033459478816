import { Tooltip } from 'antd';
import cn from 'classnames';

import { Button } from '../../../1_shared/ui';

import { ISpecialistNavigateProps } from './interfaces/ISpecialistNavigateProps';

import styles from './SpecialistNavigate.module.scss';

const SpecialistNavigate = ({
  className = '',
  slots,
}: ISpecialistNavigateProps) => (
  <div className={cn(styles.root, { [className]: className })}>
    <Tooltip
      placement="bottom"
      title={!slots?.length ? 'У специалиста нет доступных слотов' : null}
    >
      <Button
        className={styles.btnSubmit}
        htmlType="submit"
        type="primary"
        disabled={!slots?.length}
      >
        ЗАПИСАТЬСЯ
      </Button>
    </Tooltip>
    {/* {!dark && ( */}
    {/*   <Button */}
    {/*     className={styles.infoBtn} */}
    {/*     type="secondary" */}
    {/*     onClick={() => navigate(`specialist/${id}`)} */}
    {/*   > */}
    {/*     ОБ ЭКСПЕРТЕ */}
    {/*   </Button> */}
    {/* )} */}
  </div>
);

export default SpecialistNavigate;
