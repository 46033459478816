import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';

import { IWeekScheduleForm } from '../ui/interfaces/IWeekScheduleForm';

import { addScheduleOnWeek } from './api/add.schedule.api';
import { IModalScheduleAdd } from './api/interfaces/IModalScheduleAdd';

const useAddScheduleOnWeek = () => {
  const { trigger } = useSWRMutation(
    '/ss/slots/v1/generate',
    addScheduleOnWeek,
  );

  const onAdd = async (data: IWeekScheduleForm, specialist: string) => {
    const tempData = {
      specialistId: specialist,
      zoneOffset: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...data,
      dayStartAt: dayjs(data.dayStartAt).format('HH:mm'),
      dayEndAt: dayjs(data.dayEndAt).format('HH:mm'),
      breakStartAt: data.breakStartAt
        ? dayjs(data.breakStartAt).format('HH:mm')
        : null,
      breakEndAt: data.breakStartAt
        ? dayjs(data.breakEndAt).format('HH:mm')
        : null,
    };

    return await trigger(tempData as IModalScheduleAdd);
  };

  return { onAdd };
};

export default useAddScheduleOnWeek;
