import dayjs from 'dayjs';

export const getDates = (startDate: Date) => {
  const dates = [];

  for (let i = 0; i < 7; i += 1) {
    dates.push(dayjs(startDate).add(i, 'days'));
  }

  return dates;
};
