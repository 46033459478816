import { FC } from 'react';

import IPageProps from './interfaces/IPageProps';

import styles from './Page.module.scss';

const Page: FC<IPageProps> = ({ children }) => (
  <div className={styles.page}>
    {children}
  </div>
);


export default Page;
