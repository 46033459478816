import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { RoutePath } from '../../config/routes';
import { specApiInstance } from '../apiInstance';

const { useMessage } = message;

const useInterceptors = () => {
  const [messageApi, contextHolder] = useMessage();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { logout } = useAuthContext();

  const errorMessage = (errorText: string) => {
    messageApi.open({
      type: 'error',
      content: errorText,
      duration: 1,
    });
  };

  specApiInstance.interceptors.response.use(null, async error => {
    if (error?.response?.status === 401) {
      if (logout) {
        navigate(RoutePath.LOGIN, { state: { pathname, state } });
        window.location.reload();
        logout();
      }
      return error;
    }
    if (error?.response?.data?.message) {
      errorMessage(error.response.data.message);
      return error;
    }

    return error;
  });

  return { contextHolder, messageApi, errorMessage };
};

export default useInterceptors;
