import { RoutePath } from '../../../1_shared/config/routes';

import { IConfHeadersLink } from './interfaces/IConfHeadersLink';

export const headerLinks: IConfHeadersLink[] = [
  {
    label: 'Специалисты',
    url: RoutePath.SPECIALISTS,
    isAccordion: false,
  },
  {
    label: 'Персональная консультация',
    url: '#application',
    isAccordion: false,
  },
  {
    label: 'О нас',
    url: 'https://psychologi.uz/about',
    isAccordion: false,
  },
];
