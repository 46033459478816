import * as yup from 'yup';

import { LoginStatus } from '../../../1_shared/config/interfaces/LoginStatus';
import { Roles } from '../../../1_shared/config/interfaces/Roles';
import { isPhoneField } from '../lib/checkPhone';

export const schemaLogin = (
  status: LoginStatus,
  isPasswordPage: boolean,
  isLogin: boolean,
) =>
  yup.object().shape({
    login: yup.lazy(value => {
      if (isPhoneField(value)) {
        return yup
          .string()
          .matches(
            /\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/,
            'Номер должен быть в виде +7 999 999 99 99',
          )
          .required();
      }
      return yup
        .string()
        .email('Почта должна быть валидной')
        .required('Логин обязателен');
    }),
    role: yup.lazy(() => {
      if (!isPasswordPage) {
        return yup.string().required();
      }
      return yup.string();
    }),
    password: yup.lazy(() => {
      if (status === LoginStatus.NeedPassword) {
        return yup.string().required();
      }
      return yup.string();
    }),
    code: yup.lazy(() => {
      if (status === LoginStatus.CodeSent) {
        return yup.string().required();
      }
      return yup.string();
    }),
    nickname: yup
      .string()
      .when('role', ([role], schema) =>
        role === Roles.Client && status === LoginStatus.CodeSent && !isLogin
          ? schema.required()
          : schema.nullable(),
      ),
    surname: yup
      .string()
      .when('role', ([role], schema) =>
        role === Roles.Spec && status === LoginStatus.CodeSent && !isLogin
          ? schema.required()
          : schema.nullable(),
      ),
    firstName: yup
      .string()
      .when('role', ([role], schema) =>
        role === Roles.Spec && status === LoginStatus.CodeSent && !isLogin
          ? schema.required()
          : schema.nullable(),
      ),
    patronymic: yup
      .string()
      .when('role', ([role], schema) =>
        role === Roles.Spec && status === LoginStatus.CodeSent && !isLogin
          ? schema.required()
          : schema.nullable(),
      ),
  });
