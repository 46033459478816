import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Radio } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';

import { DurationType } from '1_shared/config/interfaces/DurationType';
import { SlotType } from '1_shared/config/interfaces/SlotType';
import { Button, RadioButton, Typography } from '1_shared/ui';

import { schema } from '../config/schema';
import useAddSlot from '../module/useAddSlot';

import { IEventScheduleForm } from './interfaces/IEventScheduleForm';
import { IModalScheduleEventProps } from './interfaces/IModalScheduleEventProps';

import styles from './ModalScheduleEvent.module.scss';

const ModalScheduleEvent = ({
  open,
  chooseEvent,
  disableHourAndHalf = false,
  specialist,
  onClose,
  addEvent,
}: IModalScheduleEventProps) => {
  const { control, handleSubmit, reset, formState } =
    useForm<IEventScheduleForm>({
      resolver: yupResolver(schema as any),
    });

  const { onAdd } = useAddSlot();

  const onSubmit = async (data: IEventScheduleForm) => {
    
    await onAdd(data, dayjs(chooseEvent).format('YYYY-MM-DDTHH:mm:ss'), specialist);
    addEvent();
    reset();
    onClose();
  };

  return (
    <Modal centered open={open} onCancel={onClose} footer={null}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Typography type="textM">{`Слот на ${dayjs(chooseEvent).format('D MMMM HH:mm')}`}</Typography>
        <div className={styles.item}>
          <Typography type="subtitle">ПРОДОЛЖИТЕЛЬНОСТЬ</Typography>
          <Controller
            control={control}
            name="duration"
            render={({ field: { value, onChange } }) => (
              <Radio.Group
                value={value}
                onChange={e => onChange(e.target.value)}
                className={styles.radioGroup}
              >
                <RadioButton
                  value={DurationType.Hour}
                  className={cn(styles.radio, {
                    [styles.radioChecked]: value === DurationType.Hour,
                  })}
                >
                  1 час
                </RadioButton>
                <RadioButton
                  value={DurationType.HourAndHalf}
                  disabled={disableHourAndHalf}
                  className={cn(styles.radio, {
                    [styles.radioChecked]: value === DurationType.HourAndHalf,
                    [styles.disabled]: disableHourAndHalf,
                  })}
                >
                  1,5 часа
                </RadioButton>
              </Radio.Group>
            )}
          />
        </div>
        <div className={styles.item}>
          <Typography type="subtitle">ТИП СЕССИИ</Typography>
          <Controller
            control={control}
            name="kind"
            render={({ field: { value, onChange } }) => (
              <Radio.Group
                value={value}
                onChange={e => onChange(e.target.value)}
                className={styles.radioGroup}
              >
                <RadioButton
                  value={SlotType.Personal}
                  className={cn(styles.radio, {
                    [styles.radioChecked]: value === SlotType.Personal,
                  })}
                >
                  Персональная консультация
                </RadioButton>
                <RadioButton
                  value={SlotType.Supervision}
                  className={cn(styles.radio, {
                    [styles.radioChecked]: value === SlotType.Supervision,
                  })}
                >
                  Супервизия
                </RadioButton>
              </Radio.Group>
            )}
          />
        </div>
        <div className={styles.footer}>
          <Button
            type="primary"
            disabled={!formState.isValid}
            htmlType="submit"
          >
            ПРИМЕНИТЬ
          </Button>
          <Button type="secondary" onClick={onClose}>
            ОТМЕНИТЬ
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default ModalScheduleEvent;
