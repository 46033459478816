import { Breadcrumbs2, Footer,OrderCurs } from '1_shared/ui';
import Page from '1_shared/ui/Page/ui/Page';
import { Header } from '4_widgets';

const ReactOrderCurs = () => (
  <Page>
    <Header />
    <Breadcrumbs2 />
    <OrderCurs />
    <Footer />
  </Page>
);

export default ReactOrderCurs;
