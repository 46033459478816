import React from 'react';

import { Content, Footer, Page } from '1_shared/ui';
import { Header } from '4_widgets';

import OrderForm from '../../../4_widgets/OrderForm/ui/OrderForm';

const OrderPage = (): React.ReactElement => (
  <Page>
    <Header />
    <Content><OrderForm /></Content>
    <Footer />
  </Page>
);

export default OrderPage;
