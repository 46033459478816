import { Footer, Page } from "1_shared/ui";
import { Header, PcAdminTabs } from "4_widgets";

const PcAdmin = () => (
    <Page>
        <Header />
        {/* <Content>
            <ProfileUser />
        </Content> */}
        <PcAdminTabs />
        <Footer />
    </Page>
);

export default PcAdmin;
