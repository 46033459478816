import { Content, Footer, Page } from '1_shared/ui';
import { Header } from '4_widgets';

const ClientSessions = () => (
  <Page>
    <Header />
    <Content>Сеансы</Content>
    <Footer />
  </Page>
);

export default ClientSessions;
