import { ReactElement, useState } from 'react';
import { Image } from 'antd';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IMediaContentById } from '1_shared/config/interfaces/IMediaContentById';

import specImg1 from '../../../1_shared/assets/images/specialistPhotos/spec1.jpg';
import specImg2 from '../../../1_shared/assets/images/specialistPhotos/spec2.jpg';
import specImg3 from '../../../1_shared/assets/images/specialistPhotos/spec3.jpg';

import { IPreviewListImageProps } from './interfaces/IPreviewListImageProps';

import styles from './PreviewListImage.module.scss';

const specImgArr = [specImg1, specImg2, specImg3];

const PreviewListImage = ({
  media,
  ...others
}: IPreviewListImageProps): ReactElement => {
  const [currentMedia, setCurrentMedia] = useState<IMediaContentById | null>(
    media.find(el => el.mediaContentResponse.isDefault) ?? media[0],
  );

  return (
    <div className={styles.root}>
      <div className={styles.previewMobile}>
        <Swiper
          spaceBetween={20}
          slidesPerView="auto"
          mousewheel
          modules={[Mousewheel]}
          className={styles.previewMobile}
        >
          {media
            ? media.map(img =>
                img.mediaContentResponse.mediaType === 'IMAGE' ? (
                  <SwiperSlide>
                    <img
                      alt="person"
                      key={img.mediaContentResponse.previewLink}
                      className={styles.personImg}
                      src={img.mediaContentResponse.mainMediaLink}
                    />
                  </SwiperSlide>
                ) : (
                  <img
                    alt="person"
                    key={img.mediaContentResponse.previewLink}
                    className={styles.personImg}
                    src={img?.mediaContentResponse.previewLink ?? ''}
                  />
                ),
              )
            : specImgArr.map(img => (
                <Image className={styles.personImg} src={img} />
              ))}
        </Swiper>
      </div>
      <div className={styles.previewDesktop}>
        <div className={styles.preview}>
          <img
            {...others}
            alt="person"
            className={styles.personImg}
            src={currentMedia?.mediaContentResponse.previewLink ?? ''}
          />
        </div>
        <div className={styles.shortsPhotos}>
          {media?.length > 1 &&
            media?.map(content => (
              <button
                key={content.mediaContentResponse.previewLink}
                type="button"
                className={styles.photoContainer}
                onClick={() => setCurrentMedia(content)}
              >
                <img
                  key={content.mediaContentResponse.previewLink}
                  className={styles.shortImg}
                  src={content.mediaContentResponse.previewLink}
                  alt="img"
                />
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PreviewListImage;
