import { useMemo } from 'react';
import { SWRResponse, useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

const useSWRWithCache = <Output>(
  key: string,
  callback: (url: string) => Promise<Output>,
): SWRResponse => {
  const { cache } = useSWRConfig();
  const allConfig = useSWRImmutable(
    !cache.get(key)?.data ? key : null,
    callback,
  );

  const formatData: Output = useMemo(
    () => (!cache.get(key)?.data ? allConfig.data : cache.get(key)?.data),
    [allConfig.data, cache.get(key)?.data],
  );

  return { ...allConfig, data: formatData };
};

export default useSWRWithCache;
