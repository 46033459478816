import { Accordions } from './accordions';

// eslint-disable-next-line import/no-mutable-exports
let accordions;

const initAccordions = () => {
  accordions = new Accordions();
  // Используйте в разработке экспортируемую переменную accordions, window сделан для бэкэнда
  (window as any).accordions = accordions;
};

export { initAccordions, accordions };
