import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    if (!location?.hash) {
      document.getElementById('root')?.scroll(0, 0);
    }
  }, [location.pathname, location?.hash]);

  return children;
};

export default ScrollToTop;
