import { Navigate } from 'react-router';
import {
  Anketa,
  ClientSessions,
  FirstFormSpec,
  LoginPage,
  NotFound,
  OrderPage,
  PcClient,
  PcSpec,
  ReactOrderCurs,
  ReactOrderOk,
  SpecialistPersonPage,
  SpecialistsPage,
} from '5_pages';
import PcAdmin from '5_pages/PcAdmin/ui/PcAdmin';

import { RoutePath } from '1_shared/config/routes';

import { Roles } from '../../../1_shared/config/interfaces/Roles';
import ReactOrderNotOk from '../../../5_pages/ReactOrderNotOk/ui/ReactOrderNotOk';

import { Route } from './interfaces/Route';

export const routesConfig: Route[] = [
  {
    path: RoutePath.MAIN,
    element: <Navigate to={RoutePath.SPECIALISTS} />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALISTS,
    element: <SpecialistsPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.SPECIALIST_PERSON,
    element: <SpecialistPersonPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.LOGIN,
    element: <LoginPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.PASSWORD,
    element: <LoginPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.CLIENT_CABINET,
    element: <PcClient />,
    roles: [Roles.Client],
  },
  {
    path: RoutePath.CLIENT_SESSIONS,
    element: <ClientSessions />,
    roles: [Roles.Client],
  },
  {
    path: RoutePath.SPECIALIST_CABINET,
    element: <PcSpec />,
    roles: [Roles.Spec],
  },
  {
    path: RoutePath.ADMIN_CABINET,
    element: <PcAdmin />,
    roles: [Roles.Unauthorized],
    // заменить на ↓↓ после добавления админа
    // roles: [Roles.Admin],

  },
  {
    path: RoutePath.ORDER,
    element: <OrderPage />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.REACT_ORDER_CURS,
    element: <ReactOrderCurs />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.REACT_ORDER_OK,
    element: <ReactOrderOk />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.NEED_HELP,
    element: <ReactOrderNotOk />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.FIRST_FORM,
    element: <FirstFormSpec />,
    roles: [Roles.Spec],
  },
  {
    path: RoutePath.REACT_ANKETA,
    element: <Anketa />,
    roles: [Roles.Unauthorized],
  },
  {
    path: RoutePath.NOT_FOUND,
    element: <NotFound />,
    roles: [Roles.Unauthorized],
  },
];
