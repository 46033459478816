export class Accordions {
  private openHeight: number;

  private windowWidth: number

  constructor() {
    this.openHeight = 0;
    this.windowWidth = window.innerWidth;
    this.documentClickHandler = this.documentClickHandler.bind(this);
    this.windowResizeHandler = this.windowResizeHandler.bind(this);
    this.init();
  }

  init() {
    this.fullUpdate();
    document.addEventListener('click', this.documentClickHandler);
    window.addEventListener('resize', this.windowResizeHandler);
  }

  documentClickHandler(evt: any) {
    const {target} = evt;
    if (!target.closest('[data-accordion="button"]')) {
      return;
    }

    evt.preventDefault();
    const parent = target.closest('[data-accordion="parent"]');

    if (parent.dataset.destroy && !window.matchMedia(parent.dataset.destroy).matches) {
      return;
    }

    const element = target.closest('[data-accordion="element"]');
    if (element.classList.contains('is-active')) {
      this.closeAccordion(element);
      return;
    }
    this.openAccordion(element);
  }

  windowResizeHandler() {
    if (this.windowWidth === window.innerWidth) {
      return;
    }
    this.windowWidth = window.innerWidth;
    this.updateAccordionsHeight();
  }

  closeAllAccordion(parent: any) {
    const elements = parent.querySelectorAll('[data-accordion="element"]');
    elements.forEach((element: any) => {
      const currentParent = element.closest('[data-accordion="parent"]');
      if (currentParent === parent) {
        this.closeAccordion(element);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  updateAccordionsHeight(element: any = null) {
    if (element) {
      const content = element.querySelector('[data-accordion="content"]');
      content.style.transition = 'none';
      content.style.maxHeight = `${content.scrollHeight}px`;
      setTimeout(() => {
        content.style.transition = null;
      });
      return;
    }

    const closeElements = document.querySelectorAll('[data-accordion="element"]:not(.is-active)');

    closeElements.forEach((closeElement) => {
      const parent: any = closeElement.closest('[data-accordion="parent"]');
      const content: any = closeElement.querySelector('[data-accordion="content"]');
      if (parent.dataset.destroy && !window.matchMedia(parent.dataset.destroy).matches) {
        content.style.maxHeight = '100%';
      }
    });

    const openElements = document.querySelectorAll('[data-accordion="element"].is-active');
    openElements.forEach((openElement) => {
      const content: any = openElement.querySelector('[data-accordion="content"]');
      const parent: any = openElement.closest('[data-accordion="parent"]');
      if (parent.dataset.destroy && !window.matchMedia(parent.dataset.destroy).matches) {
        content.style.maxHeight = '100%';
        return;
      }
      content.style.transition = 'none';
      content.style.maxHeight = `${content.scrollHeight}px`;
      setTimeout(() => {
        content.style.transition = null;
      });
    });
  }

  fullUpdate(parent: any = null, transition = false) {
    let openElements;
    if (parent) {
      openElements = parent.querySelectorAll('[data-accordion="element"].is-active');
    } else {
      openElements = document.querySelectorAll('[data-accordion="element"].is-active');
    }
    openElements.forEach((openElement: any) => {
      const innerParent = openElement.querySelector('[data-accordion="parent"]');
      if (innerParent) {
        return;
      }
      this.openAccordion(openElement, transition);
    });
    this.updateAccordionsHeight();
  }

  openAccordion(element: any, transition = true) {
    const parentElement = element.closest('[data-accordion="parent"]');
    const contentElement = element.querySelector('[data-accordion="content"]');
    this.openHeight += contentElement.scrollHeight;

    if (parentElement.hasAttribute('data-single')) {
      this.closeAllAccordion(parentElement);
    }

    element.classList.add('is-active');
    if (transition) {
      contentElement.style.maxHeight = `${this.openHeight}px`;
    } else {
      contentElement.style.transition = 'none';
      contentElement.style.maxHeight = `${this.openHeight}px`;
      setTimeout(() => {
        contentElement.style.transition = null;
      });
    }

    if (parentElement.closest('[data-accordion="element"]')) {
      this.openAccordion(parentElement.closest('[data-accordion="element"]'), transition);
      return;
    }

    this.openHeight = 0;
  }

  // eslint-disable-next-line class-methods-use-this
  closeAccordion(element: any, transition = true) {
    const contentElement = element.querySelector('[data-accordion="content"]');
    if (!contentElement) {
      return;
    }
    element.classList.remove('is-active');
    if (transition) {
      contentElement.style.maxHeight = '0';
    } else {
      contentElement.style.transition = 'none';
      contentElement.style.maxHeight = '0';
      setTimeout(() => {
        contentElement.style.transition = null;
      });
    }
  }
}
