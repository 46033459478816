import axios from 'axios';

import { env } from '../../env';

import { QueryInputType } from './interfaces/QueryInputType';
import { RequestType } from './interfaces/RequestType';

export const specApiInstance = axios.create({
  baseURL: env.REACT_APP_API_URL,
  withCredentials: true,
});

const mutation =
  <InputType, OutputType>(requestType: RequestType = RequestType.Post) =>
  async (
    url: string,
    { arg }: Readonly<{ arg: InputType }>,
  ): Promise<OutputType> => {
    const res = await specApiInstance(url, {
      method: requestType,
      data: arg,
    });
    return res?.data;
  };

const query =
  <T, OutputType>(
    input?: Readonly<QueryInputType<T>> | null,
    options?: Record<string, any>,
  ) =>
  async (url: string): Promise<OutputType> => {
    const params = input?.requestParams ?? null;
    const urlParams = input?.urlParams?.join('/');
    const res = await specApiInstance.get(
      `${url}${urlParams ? `/${urlParams}` : ''}`,
      {
        ...options,
        params,
      },
    );
    return res?.data;
  };

export { mutation, query };
