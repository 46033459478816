import React from 'react';
import cn from 'classnames';

import { Typography } from '1_shared/ui';

import { ILabelProps } from './ILabelProps';

import styles from './Label.module.scss';

const Label: React.FC<ILabelProps> = ({
  name,
  label,
  className,
  uppercase = true,
}) => (
  <label
    className={cn(styles.label, className, {
      [styles.labelUppercase]: uppercase,
    })}
    htmlFor={name}
  >
    <Typography type='subtitle'>{label}</Typography>
  </label>
);

export default Label;
