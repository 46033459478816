import React from 'react';
import cn from 'classnames';

import IContentProps from './interfaces/IContentProps';

import styles from './Content.module.scss';

const Content: React.FC<IContentProps> = ({
  children,
  classNames: currentClassName,
  wrapperClass,
}) => {
  const className = cn(
    {
      [styles.content]: currentClassName !== 'bg_lightBlue',
      [styles.bg_lightBlue]: currentClassName === 'bg_lightBlue',
    },
    currentClassName,
  );

  const wrapperClassname = cn(styles.contentContainer, wrapperClass);

  return (
    <div className={className}>
      <div className={wrapperClassname}>{children}</div>
    </div>
  );
};

export default Content;
