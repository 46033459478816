import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { LoginStatus } from '../../../1_shared/config/interfaces/LoginStatus';
import { Roles } from '../../../1_shared/config/interfaces/Roles';
import { RoutePath } from '../../../1_shared/config/routes';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { changeNickName } from '../../OrderForm/ui/api/order.login.service';
import { ILoginForm } from '../ui/interfaces/ILoginForm';

import {
  checkToken,
  sendOtpCode,
  signUp,
  updateUserData,
} from './api/auth.service';
import { IArgReg } from './intreface/reg/IArgReg';
import { IFirstRegInput } from './intreface/reg/IFirstRegInput';
import { IHookRegOutput } from './intreface/reg/IHookRegOutput';

const useRegister = ({
  status,
  setLoginStatus,
  isPasswordPage,
  jwt,
}: IArgReg): IHookRegOutput => {
  const navigate = useNavigate();
  const { login: loginAuth } = useAuthContext();

  const { trigger: sendCodeTr } = useSWRMutation(
    '/aim/sessions/request-code/first-time',
    sendOtpCode,
  );

  const { trigger: refreshUserData } = useSWRMutation(
    '/api/v1/user-data/refresh',
    updateUserData,
  );

  const { trigger: check, error: errorToken } = useSWRMutation(
    '/aim/check/token',
    checkToken,
  );

  const { trigger: signUpTr } = useSWRMutation('aim/api/v2/signUp', signUp);

  const { trigger: nicknamePatch } = useSWRMutation(
    '/aim/client/nickname',
    changeNickName,
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (jwt && isPasswordPage) {
      (async () => await check({ token: jwt }))();
    }
  }, [check, jwt, isPasswordPage]);

  useEffect(() => {
    if (errorToken) {
      navigate(RoutePath.LOGIN);
    }
  }, [errorToken, navigate]);

  const sendCode = async (data: ILoginForm) => {
    console.log(data);
    try {
      await sendCodeTr({
        contactInfo: String(data.login),
        role: data.role as Roles,
      });
      setLoginStatus(LoginStatus.CodeSent);
    } catch (e) {
      if (e) {
        setErrorMessage((e as any).response.data.message);
      }
    }
  };

  const updateNickname = async (name: string) => {
    await nicknamePatch({ nickname: name });
    if (loginAuth) {
      loginAuth(Roles.Client);
    }
  };

  const regWithCode = async (data: ILoginForm) => {
    const tempData: IFirstRegInput = {
      phone: data.login,
      nickname: data?.nickname || null,
      firstName: data?.firstName || null,
      surname: data?.surname || null,
      patronymic: data?.patronymic || null,
      code: data.code,
      roles: data.role as Roles,
    };

    await signUpTr(tempData);
    if (data.role === Roles.Client) {
      await updateNickname(tempData?.nickname || '');
      await refreshUserData(null);
    }
    if (loginAuth) {
      loginAuth(String(data.role).toUpperCase() as Roles);
    }
  };

  const phoneRegister = async (data: ILoginForm) => {
    if (status === LoginStatus.CodeSent) {
      await regWithCode(data);
    } else {
      await sendCode(data);
    }
  };

  const verifyUser = async (data: ILoginForm) => {
    await phoneRegister(data);
  };

  const register = async (data: ILoginForm) => {
    await verifyUser(data);
  };

  return {
    register,
    errorMessage,
    setErrorMessage,
  };
};

export default useRegister;
