import DotBannerMp4 from '1_shared/assets/video/dot_banner.mp4';

import 'app/styles/global/global.scss';
import './survey.scss';

const SurveyEmergency = () => (
  <section className="survey">
    <div className="container">
      <div className="survey__inner">
        <div className="survey__heading">
          <h2 className="survey__title section-title">Подберем специалиста</h2>
          <p className="survey__subtitle">
            Поможем разобраться, решает ли терапия ваши запросы и в каком методе
            работы эффективнее
          </p>
        </div>
        <div className="survey__btn-wrap">
          <a href="survey.html" className="survey__btn btn btn--violet">
            Пройти опрос
          </a>
        </div>
        <div className="survey-video__wrap">
          <video
            src={DotBannerMp4}
            className="survey-video"
            width="100%"
            height="auto"
            muted
            playsInline
            loop
            autoPlay
            preload="auto"
          />
        </div>
      </div>
    </div>
  </section>
);

export default SurveyEmergency;
