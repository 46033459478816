export const footerLinks = [
  {
    label: 'О платформе',
    url: 'https://psychologi.uz/about',
    isAppLink: false,
  },
  {
    label: 'Частые вопросы',
    url: 'https://psychologi.uz/about',
    isAppLink: false,
  },
  // {
  //   label: 'Вход на платформу',
  //   url: RoutePath.LOGIN,
  //   isAppLink: true,
  // },
];
