export const minuteTitles = ['минута', 'минуты', 'минут'];
export const dayTitles = ['день', 'дня', 'дней'];
export const monthTitles = ['месяц', 'месяца', 'месяцев'];
export const yearTitles = ['год', 'года', 'лет'];

export const declination = (number: number, titles: string[]) =>
  titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ];
