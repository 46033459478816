import SpriteSvg from '1_shared/assets/pictures/stack/stack.svg';

import 'app/styles/global/global.scss';
import './emergency-contacts.scss';

const EmergencyContacts = () => (
<section className="emergency-contacts">
  <div className="container">
    <div className="emergency-contacts__inner">
      <div className="emergency-contacts__half">
        <svg viewBox="0 0 49 49" width="49" height="49">
          <use href={`${SpriteSvg}#phone`} />
        </svg>
        <p>Специалист уже ждет вас<br /> на горячей линии</p>
        <span>телефон</span>
        <a href="tel:+78005553857" className="emergency-contacts__title">+7 (800) 555-38-57</a>
      </div>
      <div className="emergency-contacts__half">
        <svg viewBox="0 0 49 49" width="49" height="49">
          <use href={`${SpriteSvg}#tg-2`} />
        </svg>
        <p>Наш умный чат-бот помогает быстро<br /> продиагностировать состояние</p>
        <span>телеграм</span>
        <a href="https://t.me/dotherapybotik" className="emergency-contacts__title">@dotherapybotik</a>
      </div>
    </div>
  </div>
</section>
);

export default EmergencyContacts;