export const uzsConvert = (cost: number): string => {
    const convertedCost = cost / 100;

    const formatNumber = (num: number): string => 
         num.toString().replace(/(\.\d*?[1-9])0+$/, '$1')
    ;

    if (convertedCost >= 1_000_000) {
        return `${formatNumber(convertedCost / 1_000_000)} млн.uzs`;
    } if (convertedCost >= 1_000) {
        return `${formatNumber(convertedCost / 1_000)} т.uzs`;
    } 
        return `${formatNumber(convertedCost)} uzs`;
    
}