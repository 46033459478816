import React from 'react';
import { Flex, TimePicker } from 'antd';
import dayjs from 'dayjs';

import { TIME_FORMAT } from '1_shared/constants/dateFormat';
import { Label } from '1_shared/ui/Label';

import { ICustomTimeRangePickerProps } from './ICustomTimeRangePickerProps';

export const CustomTimeRangePicker: React.ForwardRefExoticComponent<ICustomTimeRangePickerProps> =
  React.forwardRef(
    (
      { name = '', label = '', outputFormat = TIME_FORMAT, onChange, ...props },
      ref,
    ) => {
      const handleChange = (values: any, formatString: [string, string]) => {
        const output =
          outputFormat && values
            ? [
                dayjs(values[0]).format(outputFormat),
                dayjs(values[1]).format(outputFormat),
              ]
            : values;

        onChange?.(output, formatString);
      };

      return (
        <Flex vertical>
          <Label name={name} label={label} />
          <TimePicker.RangePicker
            format="HH:mm"
            ref={ref as any}
            value={[dayjs(), dayjs()]}
            id={name}
            onChange={handleChange}
            {...props}
          />
        </Flex>
      );
    },
  );
