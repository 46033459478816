import { ReactElement } from 'react';

import { Button, Typography } from '1_shared/ui';

import { ISpecialistData } from '../../../1_shared/config/interfaces/ISpecialistData';
import KeyThemes from '../../../3_features/KeyThemsChoose/ui/KeyThemes';
import SpecialistCard from '../../SpecialistCard/ui/SpecialistCard';

import { IHelpChooseProps } from './interfaces/IHelpChooseProps';

import styles from './HelpChoose.module.scss';

const HelpChoose = ({ onChange, specs }: IHelpChooseProps): ReactElement => (
  <div className={styles.root}>
    <KeyThemes onChange={onChange} />
    {!!specs?.specList?.fourth?.length && (
      <>
        {specs?.specList?.fourth?.map((spec: ISpecialistData, index) => (
          <SpecialistCard
            spec={spec}
            key={spec?.id}
            slicedBlock
            className={index === 0 ? styles.block : undefined}
            showDescription
          />
        ))}
        {specs?.totalShowedSpecs !== specs?.total && (
          <div className={styles.viewMore}>
            <Button onClick={specs?.viewMore}>ПОКАЗАТЬ БОЛЬШЕ</Button>
          </div>
        )}
      </>
    )}
    <div className={styles.onlineCons}>
      <Typography type="title" className={styles.description}>
        Можем проконсультировать индивидуально
      </Typography>
      <Typography className={styles.description}>
        Спишемся в телеграмме, наши специалисты помогают за 10 минут
      </Typography>
      <div className={styles.btnWrapper}>
        <a
          href="https://t.me/TestUZClientBot"
          className="btn btn--violet"
          type="primary"
        >
          ПЕРЕЙТИ В ТЕЛЕГРАММ
        </a>
      </div>
    </div>
  </div>
);

export default HelpChoose;
