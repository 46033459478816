export enum LoginStatus {
  CodeSend = 'CODE_SEND',
  NotFound = 'NOT_FOUND',
  FoundUser = 'FOUND_USER',
  NeedPassword = 'NEED_PASSWORD',
  CodeSent = 'CODE_SENT',
  CodeSended = 'CODE_SENDED',
  CodeVerified = 'CODE_VERIFIED',
  None = 'NONE',
}
