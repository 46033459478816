import { mutation, query } from '../../../../1_shared/api/apiInstance';

import { IMakeOrderInput } from './interfaces/IMakeOrderInput';
import { IMakeOrderOutput } from './interfaces/iMakeOrderOutput';
import { IPromocodeInput } from './interfaces/IPromocodeInput';
import { IPromoCodeOutput } from './interfaces/IPromoCodeOutput';

const makeOrder = mutation<IMakeOrderInput, IMakeOrderOutput>();

const getPromoCode = query<IPromocodeInput, IPromoCodeOutput>;

export { makeOrder, getPromoCode };
