import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from 'antd';

import { Radio as CustomRadio, Typography } from '1_shared/ui';

import { EWellBeingStatus } from '../../../1_shared/config/interfaces/EWellBeing';
import { IAnketaForm } from '../../../1_shared/config/interfaces/IAnketaForm';

import styles from './WellBeingForm.module.scss';

const WellBeingForm = () => {
  const { control } = useFormContext<IAnketaForm>();
  return (
    <section className={styles.root}>
      <Typography type="title">Ваше самочувствие сегодня</Typography>
      <Controller
        control={control}
        name="wellBeing"
        render={({ field: { value, onChange } }) => (
          <Radio.Group
            value={value}
            onChange={onChange}
            className={styles.radioGroup}
          >
            <CustomRadio value={EWellBeingStatus.NotMe}>
              <Typography>
                Я чувствую себя хорошо, и хочу посоветоваться о другом человеке{' '}
              </Typography>
            </CustomRadio>
            <CustomRadio value={EWellBeingStatus.Ok}>
              <Typography>
                Я чувствую себя нормально, но мне нужна помощь
              </Typography>
            </CustomRadio>
            <CustomRadio value={EWellBeingStatus.Bad}>
              <Typography>Я чувствую себя плохо</Typography>
            </CustomRadio>
            <CustomRadio value={EWellBeingStatus.VeryBad}>
              <Typography>Я чувствую себя очень плохо</Typography>
            </CustomRadio>
          </Radio.Group>
        )}
      />
    </section>
  );
};

export default WellBeingForm;
