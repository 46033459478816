import 'app/styles/global/global.scss';
import './breadcrumbs.scss';

const Breadcrumbs2 = () => (
<div className="breadcrumbs">
  <div className="breadcrumbs__container container">
    <ul>
      <li><a href="https://psychologi.uz/">Psychologi Uz</a></li>
      <li><a href="https://psychologi.uz/">Каждому</a></li>
      <li><a href='https://psychologi.uz/'>Специалисты для меня</a></li>
    </ul>
  </div>
</div>
);

export default Breadcrumbs2;