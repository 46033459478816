import SpriteSvg from '1_shared/assets/pictures/stack/stack.svg';

import 'app/styles/global/global.scss';
import './next.scss';

const Next = () => (
<section className="next">
  <div className="container next__container">
    <div className="next__inner">
      <h2 className="next__title section-title">Что будет дальше</h2>
      <div className="next__list-wrapper">
        <ul className="next__list">
          <li className="next__item">
            <span>1</span>
            <svg viewBox="0 0 48 48" width="48" height="48">
              <use href={`${SpriteSvg}#mail`} />
            </svg>
            <p>Пришлем письмо со ссылкой на ваш личный кабинет</p>
          </li>
          <li className="next__item">
            <span>2</span>
            <svg viewBox="0 0 48 48" width="48" height="48">
              <use href={`${SpriteSvg}#bell`} />
            </svg>
            <p>Напомним за день о встрече</p>
          </li>
          <li className="next__item">
            <span>3</span>
            <svg viewBox="0 0 48 48" width="48" height="48">
              <use href={`${SpriteSvg}#document-clip`} />
            </svg>
            <p>Отправим инструкцию<br /> по подключению<br /> к сессии</p>
          </li>
          <li className="next__item">
            <span>4</span>
            <svg viewBox="0 0 48 48" width="48" height="48">
              <use href={`${SpriteSvg}#calendar-2`} />
            </svg>
            <p>Предложим удобные даты<br /> для продолжения</p>
          </li>
          <li className="next__item">
            <span>5</span>
            <svg viewBox="0 0 48 48" width="48" height="48">
              <use href={`${SpriteSvg}#schedule`} />
            </svg>
            <p>Предложим бонус за честный отзыв<br /> о сервисе</p>
          </li>
          <li className="next__item">
            <span>6</span>
            <svg viewBox="0 0 48 48" width="48" height="48">
              <use href={`${SpriteSvg}#chat-bubble-2`} />
            </svg>
            <p>При желании оцените работу специалиста после встречи, чтобы мы становились только лучше</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

);

export default Next;