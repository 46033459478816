import dayjs from 'dayjs';

export const checkDateNow = (date: string, format: string): string => {
  switch (date) {
    case dayjs().format(format): {
      return 'сегодня';
    }
    case dayjs().add(1, 'day').format(format): {
      return 'завтра';
    }
    default: {
      return date;
    }
  }
};
