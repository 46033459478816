import Avatar from 'antd/lib/avatar/avatar';

import { Typography } from '1_shared/ui';

import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';

import styles from './ProfileUser.module.scss';

const ProfileUser = () => {
  const { user } = useAuthContext();
  return (
    <section className={styles.root}>
      <Avatar src={user?.avatarUrl} size={100} />
      {user?.secondName && user?.firstName && !user?.isAnonymous ? (
        <Typography type="title">{`${user?.secondName} ${user?.firstName}`}</Typography>
      ) : (
        <Typography type="title">{user?.nickname}</Typography>
      )}
      {user?.isAnonymous && <Typography type="title">Аноним</Typography>}
      <div className={styles.wrapInfo}>
        <Typography type="description">{user?.email?.toUpperCase()}</Typography>
        <Typography type="description">•</Typography>
        <Typography type="description">
          {phoneConvert(user?.phone || '')}
        </Typography>
      </div>
    </section>
  );
};

export default ProfileUser;
