import cn from 'classnames';

import { Typography } from '1_shared/ui';

import { ISpecSpecializtionProps } from './interfaces/ISpecSpecializtionProps';

import styles from './SpecSpecialization.module.scss';

const SpecSpecialization = ({
  specialization,
  className = '',
  dark = false,
}: ISpecSpecializtionProps) => (
  <div className={cn(styles.root, { [className]: className !== '' })}>
    <Typography type="description">СПЕЦИАЛИЗАЦИЯ</Typography>
    <div>
      {specialization.slice(0,5).map((spec, index) => (
        <Typography key={spec} className={cn({[styles.darkText]: dark})}>
          {spec}
          {index !== specialization.length - 1 ? ', ' : '. '}
        </Typography>
      ))}
    </div>
  </div>
);

export default SpecSpecialization;
