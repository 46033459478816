import * as yup from 'yup';

export const schema = yup.object().shape({
  dates: yup.array<{}, string>().required(),
  dayStartAt: yup.string().required(),
  dayEndAt: yup.string().required(),
  breakStartAt: yup.string(),
  breakEndAt: yup
    .string()
    .when('breakStartAt', ([breakStartAt], schema) =>
      breakStartAt ? schema.required() : schema.nullable(),
    ),
  duration: yup.string().required(),
});
