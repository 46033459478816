import React from 'react';
import { Flex, Input, InputRef } from 'antd';

import { Label } from '../../Label/index';

import { ICustomInputProps } from './ICustomInputProps';

const CustomInput: React.ForwardRefExoticComponent<
  ICustomInputProps & React.RefAttributes<InputRef>
> = React.forwardRef(({ label, name = '', id, ...props }, ref) => (
  <Flex vertical>
    {label && <Label name={name} label={label} />}
    <Input
      id={id}
      name={name}
      ref={ref}
      style={{
        fontFamily: 'Inter, sans-serif',
      }}
      {...props}
    />
  </Flex>
));

export default CustomInput;
