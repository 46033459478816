import { mutation, query } from '1_shared/api/apiInstance';

import { ISpecialistData } from '../../../../1_shared/config/interfaces/ISpecialistData';
import { IAuthByPassword } from '../intreface/login/IAuthByPassword';
import { ILoginWithCode } from '../intreface/login/ILoginWithCode';
import { IRequestCode } from '../intreface/login/IRequestCode';
import { IChekTokenInput } from '../intreface/reg/IChekTokenInput';
import { IFirstRegInput } from '../intreface/reg/IFirstRegInput';
import { IRegOutput } from '../intreface/reg/IRegOutput';
import { IRegWIthPassword } from '../intreface/reg/IRegWIthPassword';
import { ISendOtpCodeInput } from '../intreface/reg/ISendOtpCodeInput';

export const sendCodeReq = mutation<IRequestCode, any>();

export const loginByCode = mutation<ILoginWithCode, null>();

export const loginByPassword = mutation<IAuthByPassword, null>();

export const registerByPassword = mutation<IRegWIthPassword, IRegOutput>();

export const profileSpec = query<null, ISpecialistData>();

export const checkToken = mutation<IChekTokenInput, null>();

export const signUp = mutation<IFirstRegInput, string>();

export const sendOtpCode = mutation<ISendOtpCodeInput, null>();

export const updateUserData = mutation<null, null>();
