import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SWRConfig } from 'swr';

import themeConfig from '1_shared/lib/theme';

import PrivateRouter from './module/provider/PrivateRouter';

import 'app/styles/globals.scss';

import 'dayjs/locale/ru';

dayjs.locale('ru');
dayjs.extend(customParseFormat);

const App = () => (
    <ConfigProvider locale={ruRU} theme={themeConfig}>
      <SWRConfig value={{ provider: () => new Map() }}>
        <main >
          <PrivateRouter />
        </main>
      </SWRConfig>
    </ConfigProvider>
  );
export default App;
