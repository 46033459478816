import useSWRMutation from 'swr/mutation';

import { IEventScheduleForm } from '../ui/interfaces/IEventScheduleForm';

import { addSlot } from './api/addSlot.api';
import { IAddSlot } from './api/interfaces/IAddSlot';

const useAddSlot = () => {
  const { trigger } = useSWRMutation(
    `/ss/slots/v1/add?zoneOffset=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    addSlot,
  );

  const onAdd = async (data: IEventScheduleForm, time: string, specialist: string) => {
    const tempData: IAddSlot = {
      specialistId: specialist,
      timePoint: time,
      kind: data.kind,
      duration: data.duration,
      capacity: 1,
    };

    return await trigger(tempData);
  };

  return { onAdd };
};

export default useAddSlot;
