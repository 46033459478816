import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Button, Tabs } from '1_shared/ui';

import { dayTimeConfig } from '../../../1_shared/config/dayTimeConfig';
import { EDayTime } from '../../../1_shared/config/interfaces/EDayTime';
import { IAnketaForm } from '../../../1_shared/config/interfaces/IAnketaForm';
import { RoutePath } from '../../../1_shared/config/routes';
import { itemsForm } from '../config/itemsConfig';

import styles from './AnketaTabs.module.scss';

const AnketaTabsForm = () => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const methods = useForm<IAnketaForm>();
  const { handleSubmit } = methods;
  const navigate = useNavigate();

  const goNext = () => {
    setActiveTab(prev => String(Number(prev) + 1));
  };

  const goPrevious = () => {
    setActiveTab(prev => String(Number(prev) - 1));
  };

  const onSubmit = (data: IAnketaForm) => {
    const tempData = {
      experienceSortOrder: 'DESC',
      slotsFilter: {
        dayType: data.dayType || undefined,
        ...dayTimeConfig[data.time as EDayTime],
      },
      keyThemes: data.keyThemas || undefined,
      sex: data.sex || undefined,
    };
    navigate(RoutePath.SPECIALISTS, {
      state: { filters: tempData },
    });
  };

  return (
    <section className={styles.testing__inner}>
      <FormProvider {...methods}>
        <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            classNameTabs={styles.tabs}
            items={itemsForm}
            disabledToggle
            activeTab={activeTab}
            defaultActiveTabIndex="1"
          />
          <footer
            className={cn(styles.btnWrapper, {
              [styles.btnFirstWrap]:
                itemsForm && activeTab === itemsForm[0].key,
            })}
          >
            {itemsForm && activeTab !== itemsForm[0].key && (
              <Button
                className={styles.btnPrevios}
                type="secondary"
                onClick={goPrevious}
              >
                НАЗАД
              </Button>
            )}
            {itemsForm && activeTab !== itemsForm[itemsForm.length - 1].key && (
              <Button
                className={styles.btnNext}
                type="primary"
                onClick={goNext}
              >
                ДАЛЕЕ
              </Button>
            )}
            {itemsForm && activeTab === itemsForm[itemsForm.length - 1].key && (
              <Button
                htmlType="submit"
                className={styles.btnSubmit}
                type="primary"
              >
                ПОДОБРАТЬ СПЕЦИАЛИСТА
              </Button>
            )}
          </footer>
        </form>
      </FormProvider>
    </section>
  );
};

export default AnketaTabsForm;
