import { Link } from 'react-router-dom';

import LogoUZMobile from '1_shared/assets/pictures/PsyMobileUZ.svg';
import LogoUZ from '1_shared/assets/pictures/PsyUZ.svg';

import { footerLinks } from '../config/footerLinks';

import 'app/styles/global/global.scss';
import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer__inner">
        <div className="footer__row">
          <div className="footer__half footer__half--min">
            <a href="https://psychologi.uz" className="footer__logo">
              <picture>
                <source media="(max-width: 768px)" srcSet={LogoUZMobile} />
                <img
                  src={LogoUZ}
                  alt="PsychologiUz ваша точка опоры"
                  width="166"
                  height="64"
                />
              </picture>
            </a>
          </div>
        </div>

        <div className="footer__row">
          <div className="footer__half">
            <ul className="footer__nav">
              {footerLinks.map(item =>
                item.isAppLink ? (
                  <li key={item.label} className="footer__nav-item">
                    <Link to={item.url} className="footer__nav-link">
                      {item.label}
                    </Link>
                  </li>
                ) : (
                  <li key={item.label} className="footer__nav-item">
                    <a href={item.url} className="footer__nav-link">
                      {item.label}
                    </a>
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>

        <div className="footer__row">
          <div className="footer__half footer__half--min">
            <p className="footer__subtitle">Мы в социальных сетях</p>
            <ul className="footer__socials">
              <li className="footer__socials-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a aria-label="ВКонтакте" className="icon--vk" />
              </li>
              <li className="footer__socials-item">
                <a
                  href="https://t.me/TestUZClientBot"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Телеграм"
                  className="icon--tg"
                />
              </li>
              <li className="footer__socials-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a aria-label="Ютуб" className="icon--yt" />
              </li>
              <li className="footer__socials-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a aria-label="Спектрум" className="icon--spectrum" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
