import PsyLogo from '1_shared/assets/pictures/PsyMobileUZ.svg';
import { IMediaContentById } from '1_shared/config/interfaces/IMediaContentById';
import { MediaType } from '1_shared/config/interfaces/MediaType';

export const configMedia: IMediaContentById = {
  id: '1',
  isDefault: true,
  mediaContentResponse: {
    isDefault: true,
    mediaType: MediaType.Image,
    mainMediaLink: PsyLogo,
    previewLink: PsyLogo,
  },
};
