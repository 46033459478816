import { Typography } from '../../../1_shared/ui';

import { ISpecialistAvatarProps } from './interfaces/ISpecialistAvatarProps';

import styles from './SpecialistAvatar.module.scss';

const SpecialistAvatar = ({
  firstName,
  lastName,
  patronymic,
  imgPreview,
}: ISpecialistAvatarProps): React.ReactElement => (
  <div className={styles.root}>
    <div className={styles.wrapperAvatar}>
      <img src={imgPreview} alt="avatar" className={styles.imgAvatar} />
    </div>
    <Typography>{`${lastName ?? ''} ${firstName ?? ''} ${patronymic ?? ''}`}</Typography>
  </div>
);

export default SpecialistAvatar;
