import 'app/styles/global/global.scss';
import './feedback.scss';

const Feedback = () => (
<section className="feedback">
  <div className="feedback__container container">
    <div className="feedback__heading">
      <h2 className="feedback__title section-title">Люди, которым<br /> мы <span>помогли</span></h2>
      <p className="feedback__subtitle">Мы стремимся к прозрачности и публикуем все отзывы, даже негативные. Отзыв может оставить любой пользователь, который провел хотя бы одну консультацию на платформе</p>
    </div>
    <div className="feedback__list-wrapper">
      <ul className="feedback__list">
        <li className="feedback__item feedback__item--w-plus">
          <p>Я очень довольна этим сервисом! Нашла замечательного психолога рядом с домом. Удобный интерфейс и легкий поиск по фильтрам. Спасибо</p>
          <span>Анна, 28 лет</span>
        </li>
        <li className="feedback__item">
          <p>Интересная идея, но мне хотелось бы больше информации о квалификации психологов. В целом сервис полезный и функциональный</p>
          <span>Михаил, 35 лет</span>
        </li>
        <li className="feedback__item feedback__item--w-heart">
          <p>Отличный сервис для поиска специалистов. Особенно понравилась возможность читать отзывы других клиентов. Это помогло мне сделать правильный выбор.</p>
          <span>Екатерина, 40 лет</span>
        </li>
        <li className="feedback__item">
          <p>Приложение работает хорошо, но хотелось бы больше опций для персонализированного поиска. В остальном, сервис очень удобен в использовании.</p>
          <span>Алексей, 23 года</span>
        </li>
      </ul>
    </div>
  </div>
</section>
);

export default Feedback;