import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { Typography } from '../../../1_shared/ui';

import { ITimerProps } from './interfaces/ITimerProps';

import styles from './TimerSession.module.scss';

const TimerSession = ({ end, mutate }: ITimerProps) => {
  const endTime = dayjs(end, 'YYYY-MM-DDTHH:mm:ss');

  const [timer, setTimer] = useState<number>(endTime.diff(dayjs(), 'seconds'));

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(prev => prev - 1);
      } else {
        if(mutate) mutate();
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [end]);

  const minutes = useMemo(() => Math.floor(timer / 60), [timer]);

  const seconds = useMemo(() => timer - minutes * 60, [timer, minutes]);

  return (
    <div className={styles.root}>
      <Typography type="subtitle">ВРЕМЯ СЕАНСА</Typography>
      <Typography>{`${minutes}:${seconds}`}</Typography>
    </div>
  );
};

export default TimerSession;
