import { ReactElement } from 'react';

import Close from '1_shared/assets/pictures/close.svg';
import { Typography } from '1_shared/ui';

import { IChipsSelectedProps } from './interfaces/IChipsSelectedProps';

import styles from './ChipsSelected.module.scss';

const ChipsSelected = ({
  value,
  onDelete,
}: IChipsSelectedProps): ReactElement => (
  <div className={styles.root}>
    <Typography className={styles.text}>{value}</Typography>
    <button className={styles.imgButton} type="button" onClick={onDelete}>
      <img src={Close} alt="close" className={styles.imgClose}/>
    </button>
  </div>
);

export default ChipsSelected;
