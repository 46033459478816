import { useState } from 'react';
import { Col, Row } from 'antd';

import { phoneFormConvert, uzPhoneFormConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { MaskInput, Select, Typography } from '../../../1_shared/ui';

import { IPhoneOrderInputProps } from './interfaces/IPhoneOrderInputProps';

import styles from './PhoneInput.module.scss';

const countryCodes = [
  { label: '+998', value: '+998' },
  { label: '+7', value: '+7' },
];

const PhoneOrderInput = ({ value, onChange }: IPhoneOrderInputProps) => {
  const [selectedCode, setSelectedCode] = useState('');

  const handleSelectChange = (code: string) => {
    setSelectedCode(code);
    onChange(code);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputPhone = e.target.value;
    const formattedPhone = selectedCode === '+998'
      ? uzPhoneFormConvert(selectedCode + inputPhone.slice(selectedCode.length))
      : phoneFormConvert(selectedCode + inputPhone.slice(selectedCode.length));
    onChange(formattedPhone);
  };
  return(
    <div className={styles.row}>
      <Typography type="subtitle" className={styles.rowText}>
        ТЕЛЕФОН
      </Typography>
      <Row>
        <Col span={6}>
        <Select
          options={countryCodes}
          value={selectedCode}
          onChange={handleSelectChange}
          className={styles.codeSelect}
        />
        </Col>
        <Col span={18}>
        <MaskInput
          size="middle"
          value={value}
          onChange={handleInputChange}
          className={styles.numInput}
          disabled={!selectedCode}
          mask=""
        />
        </Col>

      </Row>
    </div>
  );
}
export default PhoneOrderInput;
