import { mutation } from '../../../1_shared/api/apiInstance';
import { RequestType } from '../../../1_shared/api/interfaces/RequestType';

import { ICancelInput } from './interfaces/ICancelInput';
import { IReminderSwitchInput } from './interfaces/IReminderSwitchInput';

export const cancelSession = mutation<ICancelInput, null>();

export const reminderSwitch = mutation<IReminderSwitchInput, null>(
  RequestType.Patch,
);
