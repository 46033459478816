import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RcFile } from 'antd/es/upload';
import cn from 'classnames';
import useSWRMutation from 'swr/mutation';

import { Button, Tabs } from '../../../1_shared/ui';
import { tabItems } from '../config/tabItems';
import { fillForm } from '../module/api/fillForm.servise';
import { IFirstFormSpec } from '../module/interfaces/IFirstFormSpec';

import styles from './FirstFormTabs.module.scss';

const FirstFormTabs = () => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const methods = useForm<IFirstFormSpec>();
  const { handleSubmit } = methods;
  const { trigger: fillFormTr } = useSWRMutation(
    '/spec/specialist/fill-form',
    fillForm,
  );

  const goNext = () => {
    setActiveTab(prev => String(Number(prev) + 1));
  };

  const goPrevious = () => {
    setActiveTab(prev => String(Number(prev) - 1));
  };

  const onSubmit = async (data: IFirstFormSpec) => {
    const tempData = {
      data: new Blob([JSON.stringify(data.data)], {
        type: 'application/json',
      }),
      avatar: data.avatar.originFileObj as RcFile,
      mediaFiles: data.mediaFiles.map(el => el.originFileObj) as RcFile[],
    };

    const dataSpec = await fillFormTr(tempData as any);
    console.log(dataSpec);
    // setSpecUser(dataSpec as any);
    // navigate(RoutePath.MAIN);
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Tabs
          classNameTabs={styles.tabs}
          items={tabItems}
          disabledToggle
          activeTab={activeTab}
          defaultActiveTabIndex="1"
        />
        <footer
          className={cn(styles.btnWrapper, {
            [styles.btnFirstWrap]: tabItems && activeTab === tabItems[0].key,
          })}
        >
          {tabItems && activeTab !== tabItems[0].key && (
            <Button
              className={styles.btnPrevios}
              type="secondary"
              onClick={goPrevious}
            >
              НАЗАД
            </Button>
          )}
          {tabItems && activeTab !== tabItems[tabItems.length - 1].key && (
            <Button className={styles.btnNext} type="primary" onClick={goNext}>
              ДАЛЕЕ
            </Button>
          )}
          {tabItems && activeTab === tabItems[tabItems.length - 1].key && (
            <Button
              htmlType="submit"
              className={styles.btnSubmit}
              type="primary"
            >
              ОТПРАВИТЬ
            </Button>
          )}
        </footer>
      </FormProvider>
    </form>
  );
};

export default FirstFormTabs;
