import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { IChipsProps } from './intrefaces/IChipsProps';

import styles from './Chips.module.scss';

const Chips = (props: PropsWithChildren<IChipsProps>) => {
  const { className, children } = props;

  return (
    <div {...props} className={cn(className, styles.root)}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Chips;
