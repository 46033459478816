import { IOption } from '1_shared/config/interfaces/IOption';

import { ELanguage } from '../config/interfaces/ELanguage';

export const EXPERIENCE_FILTER = {
  FROM_THREE_YEARS: 'FROM_THREE_YEARS',
  FROM_FIVE_YEARS: 'FROM_FIVE_YEARS',
  FROM_TEN_YEARS: 'FROM_TEN_YEARS',
};

export const experienceFilterOptions: IOption[] = [
  { value: EXPERIENCE_FILTER.FROM_THREE_YEARS, label: 'От 3-х лет' },
  { value: EXPERIENCE_FILTER.FROM_FIVE_YEARS, label: 'От 5-ти лет' },
  { value: EXPERIENCE_FILTER.FROM_TEN_YEARS, label: 'От 10-ти лет' },
];

export const SEX_FILTER = {
  MALE: 'male',
  FEMALE: 'female',
};

export const sexFilterOptions: IOption[] = [
  { value: SEX_FILTER.MALE, label: 'Мужской' },
  { value: SEX_FILTER.FEMALE, label: 'Женский' },
];

export const languageOpt: IOption[] = [
  { value: ELanguage.Rus, label: 'Русский' },
  { value: ELanguage.Uz, label: 'Узбекский' },
  { value: ELanguage.Kz, label: 'Казахский' },
];

export const DAY_TYPE_FILTER = {
  WEEKDAY: 'WEEKDAY',
  WEEKEND: 'WEEKEND',
};

export const dayTypeFilterOptions: IOption[] = [
  { value: DAY_TYPE_FILTER.WEEKDAY, label: 'Будни' },
  { value: DAY_TYPE_FILTER.WEEKEND, label: 'Выходные' },
];

export const experienceOptions = [
  { value: 'DESC', label: 'Сначала более опытные' },
  { value: 'ASC', label: 'Сначала менее опытные' },
];

export const availableOptions = [
  { value: 'SHOW_ONLY_WITH_SLOTS', label: 'Показать доступных специалистов' },
  { value: 'SHOW_ALL', label: 'Показать всех специалистов' },
];
