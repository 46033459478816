import cn from 'classnames';
import dayjs from 'dayjs';

import { days } from '1_shared/constants/days';
import { Typography } from '1_shared/ui';

import { IScheduleHeaderProps } from './interfaces/IScheduleHeaderProps';

import styles from './ScheduleHeader.module.scss';

const ScheduleHeader = ({ date, label }: IScheduleHeaderProps) => (
  <div className={styles.root}>
    <Typography
      type="subtitle"
      className={cn(styles.titleDefault, {
        [styles.titleToday]: label.includes(dayjs().format('DD')),
      })}
    >
      {dayjs(date).format('D MMMM')}
    </Typography>
    <Typography type="description" className={styles.descr}>
      {days[dayjs(date).day()].toUpperCase()}
    </Typography>
  </div>
);

export default ScheduleHeader;
