import { useNavigate } from 'react-router-dom';

import { Button, Typography } from '../../../1_shared/ui';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div className={styles.titleWrap}>
        <Typography type="title">404</Typography>
        <Button type="primary" onClick={() => navigate('/')}>
          ВЕРНУТЬСЯ НА ГЛАВНУЮ
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
