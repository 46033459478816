import { specApiInstance } from "1_shared/api/apiInstance";

const useDeleteSlot = (
    slotId: string,
    deleteEvent: (slotId: string) => void,
  ) => {
    const deleteSlot = async () => {
      await specApiInstance.delete(`/ss/slots/v1/${slotId}`);
      deleteEvent(slotId);
    };
  
    return { deleteSlot };
  };
  
  export default useDeleteSlot;
  