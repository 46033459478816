import { TabsProps } from 'antd';

import Schedule from '3_features/Schedule/ui/Schedule';


export const itemsTabs: TabsProps['items'] = [
  {
    key: '1',
    label: 'Расписание',
    children: <Schedule />,
  },
];
