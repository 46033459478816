const separateParams = (stringRoute: string) =>
  stringRoute
    ?.split('?')?.[1]
    ?.split('&')
    ?.map(param => param?.split('='))
    ?.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue?.[0]]: currentValue?.[1],
      }),
      {},
    );

export default separateParams;
