import type { ThemeConfig } from 'antd';

import variables from '1_shared/config/variables';

const themeConfig: ThemeConfig = {
  token: {
    fontSize: 14,
    fontSizeLG: 24,
    fontSizeXL: 18,
    fontSizeHeading1: 26,
    fontSizeHeading2: 18,
    fontSizeHeading3: 15,
    fontSizeHeading4: 14,
    fontSizeHeading5: 12,
    fontFamily: 'Inter, sans-serif',
  },
  components: {
    Input: {
      hoverBorderColor: variables.primaryBtnColor,
      activeBorderColor: variables.primaryBtnColor,
    },
    Checkbox: {
      colorPrimary: variables.primaryBtnColor,
      colorPrimaryHover: variables.primaryBtnHoverColor,
    },
    Button: {
      fontSize: 12,
      colorPrimary: variables.primaryBtnColor,
      colorPrimaryHover: variables.primaryBtnHoverColor,
      lineWidth: 2,
      controlHeightSM: 27,
      controlHeight: 32,
      controlHeightLG: 69,
      paddingContentHorizontal: 22,
    },
    Select: {
      colorPrimaryHover: variables.primaryBtnColor,
      colorPrimaryActive: variables.primaryBtnColor,
      colorPrimary: variables.primaryBtnColor,
    },
    Radio: {
      buttonSolidCheckedHoverBg: variables.primaryBtnColor,
      colorPrimary: variables.primaryBtnColor,
      padding: 11,
    },
  },
};

export default themeConfig;
