function menuToggle(navMain) {
  if (navMain.classList.contains('header--opened')) {
    navMain.classList.remove('header--opened');
  } else {
    navMain.classList.add('header--opened');
  }
  if (document.body.classList.contains('opened-menu')) {
    document.body.classList.remove('opened-menu');
  } else {
    document.body.classList.remove('opened-menu');
  }
}

export { menuToggle };
