import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';

import { RoutePath } from '1_shared/config/routes';
import { timeConfig } from '1_shared/config/timeConfig';
import { uzsConvert } from '1_shared/lib/helpers/uzsConvert';
import { Button, Typography } from '1_shared/ui';

import { DurationType } from '../../../1_shared/config/interfaces/DurationType';
import { ISpecialistReserveSlotProps } from '../types/types';

import styles from './SpecialistReserveSlot.module.scss';

const SpecialistReserveSlot: React.FC<ISpecialistReserveSlotProps> = ({
  spec,
  slot,
  specialistId,
}) => {
  const navigate = useNavigate();
  const duration = slot?.duration
    ? timeConfig[slot?.duration as DurationType]
    : timeConfig[spec.slots[0]?.duration as DurationType];
  const cost = slot?.cost ? slot.cost : spec?.sessionsInfo[0]?.price;
  return (
    <div className={styles.root}>
      <div className={styles.reserveInfo}>
        <div className={styles.reserveInfo__item}>
          <Typography className={styles.description} type="description">
            СЕССИЯ
          </Typography>
          <Typography className={styles.titleInfo} type="h3">
            {duration}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={styles.description} type="description">
            ЦЕНА СЕССИИ
          </Typography>
          <Typography type="h3" className={styles.titleInfo}>
            {uzsConvert(cost)}
          </Typography>
        </div>
      </div>
      <Tooltip
        placement="bottom"
        title={
          !spec?.slots?.length ? 'У специалиста нет доступных слотов' : null
        }
      >
        <Button
          type="primary"
          onClick={() => {
            navigate(RoutePath.ORDER, {
              state: { id: specialistId, slot: slot || spec.slots[0] },
            });
          }}
          disabled={!spec?.slots?.length}
          className={styles.orderBtn}
        >
          ЗАПИСАТЬСЯ
        </Button>
      </Tooltip>
    </div>
  );
};

export default SpecialistReserveSlot;
