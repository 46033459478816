import { useEffect, useMemo } from 'react';
import { Route, Routes as RoutesPath, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import useInterceptors from '../../../1_shared/api/hooks/useInterceptors';
import { Roles } from '../../../1_shared/config/interfaces/Roles';
import { createLocationString, getParamsUtm, getParamsWithoutUtm } from '../../../1_shared/helpers/locationHelpers';
import separateParams from '../../../1_shared/helpers/separateParams';
import { routesConfig } from '../../config/router';
import NotificationContext from '../lib/context/NotificationContext';
import { useAuthContext } from '../lib/hooks/useAuthContext';

const routesConfigBase = routesConfig;

const Routes = () => {
  const { role } = useAuthContext();
  const { contextHolder, messageApi } = useInterceptors();

  // UTM-functional
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search.includes('utm')) {
      const utmParams = getParamsUtm(separateParams(location.search));
      const parameters = createLocationString(
        getParamsWithoutUtm(separateParams(location.search)),
      );

      localStorage.setItem(
        'utm',
        JSON.stringify({ ...utmParams, queryParams: location.search }),
      );
      navigate(`${location.pathname}?${parameters}`);
    }
  }, [location, navigate]);

  const router = useMemo(
    () =>
      routesConfigBase
        .filter(
          route =>
            route.roles.includes(role) ||
            route.roles.includes(Roles.Unauthorized),
        )
        .map(route => ({
          path: route.path,
          element: route.element,
        })),
    [role],
  );

  return (
    <section>
      <NotificationContext.Provider value={{ messageApi }}>
        <RoutesPath>
          {router.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </RoutesPath>
        {contextHolder}
      </NotificationContext.Provider>
    </section>
  );
};

export default Routes;
