import { initAccordions } from './init-accordion';

import 'app/styles/global/global.scss';
import './data-accordion.scss';
import './faq.scss';

initAccordions();
const Faq = () => (
  <section className="faq" id="faq">
    <div className="faq__inner container">
      <div className="faq__half">
        <h2 className="faq__title section-title">
          Отвечаем
          <br /> на ваши вопросы
        </h2>
        <p className="faq__subtitle">О ментальном здоровье и заботе о себе</p>
      </div>
      <div className="faq__half">
        <ul className="faq__accordion" data-accordion="parent" data-single>
          <li className="faq__accordion-item" data-accordion="element">
            <a href="/" className="faq__accordion-link" data-accordion="button">
              Зачем / когда нужен психолог?
            </a>
            <div className="faq__accordion-content" data-accordion="content">
              <div className="faq__accordion-inner">
                <p>
                  Когда человек не может самостоятельно справиться со своими
                  чувствами и переживаниями, у него не хватает сил и собственных
                  ресурсов, ему может помочь взгляд незаинтересованного лица на
                  его проблемную ситуацию, профессиональный взгляд со стороны.
                </p>
                <p>В основном, к психологу обращаются в таких случаях как:</p>
                <ul>
                  <li>
                    травматические и стрессовые ситуации: смерть, развод,
                    насилие, тяжелое заболевание и т.п., а также беременность,
                    рождение ребенка, переезд на новое место жительства, переход
                    на новую работу и др.
                  </li>
                  <li>
                    когда человек испытывает различные трудности и проблемы в
                    ситуациях общения и взаимодействия с другими людьми
                    (близкими, детьми, сотрудниками и т.д.)
                  </li>
                  <li>
                    когда есть какие-то телесные симптомы или психосоматические
                    заболевания (например, нейродермит, вегетососудистая
                    дистония, синдром хронической усталости и др.)
                  </li>
                  <li>
                    когда человек испытывает ощущение, что «что-то не так» с ним
                    или с его окружением и у него есть желание поменять свою
                    жизнь к лучшему
                  </li>
                  <li>
                    любые другие причины, требующие поддержки или помощи со
                    стороны
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="faq__accordion-item" data-accordion="element">
            <a href="/" className="faq__accordion-link" data-accordion="button">
              Чем специалисты отличаются друг от друга?
            </a>
            <div className="faq__accordion-content" data-accordion="content">
              <div className="faq__accordion-inner">
                <p>
                  <b>Психолог-консультант</b> – это специалист с базовым
                  психологическим образованием, который владеет методами оценки
                  психических проявлений других людей и обладает умениями
                  психологически корректировать и моделировать данные
                  проявления.
                </p>
                <p>
                  Это не врач, то есть не имеет специального медицинского
                  образования; соответственно не ставит медицинских диагнозов,
                  не лечит, не выписывает и не назначает лекарственных
                  препаратов.
                </p>
                <p>
                  Психолог работает с психически здоровыми людьми (клиентами, а
                  не больными), имеющими какие-то трудности или попавшими в
                  сложную жизненную ситуацию.
                </p>
                <p>
                  В рамках психологического консультирования работают с
                  актуальным состоянием клиента, в рамках психотерапии идет
                  глубинный анализ причин появления симптомов.
                </p>
                <br />
                <p>
                  <b>Психотерапевт</b> – психолог со специальной
                  квалификационной подготовкой (специалист, бакалавр, магистр
                  психологии, курсы профпереподготовки), который помимо базового
                  психологического образования имеет профессиональную
                  переподготовку по одному или нескольким направлениям
                  (психолог, который получил дополнительное (не менее 1 года)
                  образование в том или ином направлении, часто это образование
                  занимает несколько лет (как, например, психоанализ), и
                  прошедший личную терапию и супервизию.
                </p>
                <p>
                  В рамках психотерапии идет более глубинная проработка проблем
                  клиента.
                </p>
                <br />
                <p>
                  <b>Клинический психолог</b> – это человек с высшим
                  образованием по специалитету, или после курсов
                  профпереподготовки. Занимается проблемами психологической
                  диагностики, коррекции и реабилитации психически нестабильных
                  людей, и людей в пограничном состоянии, оказывает помощь,
                  содействует родственникам нездоровых людей в налаживании
                  взаимоотношений с их близкими.
                </p>
                <p>
                  Консультирование клиентов, индивидуальное и групповое,
                  реабилитация, работа с родственниками клиента, мотивирование.
                </p>
                <br />
                <p>
                  <b>Медицинский психотерапевт</b> – это человек с высшим
                  медицинским образованием по специальности «лечебное дело»,
                  получивший подготовку по специальности «психиатрия» и
                  дальнейшую подготовку по психотерапии. Для краткости их часто
                  и называют просто «психотерапевт». Это врач, который оказывает
                  человеку профессиональную помощь в решении проблем, связанных
                  с психическим здоровьем, но не с психопатологией. При наличии
                  психопатологии необходимо обратиться за помощью к
                  врачу-психиатру.
                </p>
                <br />
                <p>
                  <b>Психиатр</b> – врач, получивший специальную подготовку (в
                  интернатуре, на курсах усовершенствования (повышения
                  квалификации)) по психиатрии и владеющий методами диагностики,
                  лечения, профилактики и экспертизы психических заболеваний.
                </p>
              </div>
            </div>
          </li>
          <li className="faq__accordion-item" data-accordion="element">
            <a href="/" className="faq__accordion-link" data-accordion="button">
              Что такое психологическое консультирование?
            </a>
            <div className="faq__accordion-content" data-accordion="content">
              <div className="faq__accordion-inner">
                <p>
                  <b>Психологическое консультирование</b> – профессиональная
                  помощь клиенту в поиске решения проблемной ситуации. Это
                  оказание помощи психически и соматически здоровым людям в
                  ситуациях, когда они сталкиваются с проблемами. Объектами
                  психологического консультирования являются психически здоровые
                  люди. Термин психологическое консультирование применим к
                  ситуациям диалога, откровенного обсуждения,
                  субъект-субъектного общения.
                </p>
                <p>
                  Психологическое консультирование ориентировано, в большей
                  степени, на урегулирование отношений клиента с другими людьми.
                  Работа идет с актуальным состоянием клиента.
                </p>
              </div>
            </div>
          </li>
          <li className="faq__accordion-item" data-accordion="element">
            <a href="/" className="faq__accordion-link" data-accordion="button">
              Сколько сессий нужно ходить?
            </a>
            <div className="faq__accordion-content" data-accordion="content">
              <div className="faq__accordion-inner">
                <p>
                  Все индивидуально, и зависит от проблемы, от вида помощи, от
                  готовности/возможности клиента, а так же от направления, в
                  котором работает психолог. Минимум от 5 сессий и до нескольких
                  лет. В некоторых ситуациях достаточно будет минимального
                  количества сессий, а в некоторые несколько месяцев или лет.
                </p>
              </div>
            </div>
          </li>
          <li className="faq__accordion-item" data-accordion="element">
            <a href="/" className="faq__accordion-link" data-accordion="button">
              Как проходит сессия?
            </a>
            <div className="faq__accordion-content" data-accordion="content">
              <div className="faq__accordion-inner">
                <p>
                  Сессия проходит в спокойной, безопасной обстановке.
                  Рекомендуем уединиться, чтобы никто и ничто не мешало и не
                  отвлекало. Советуем быть максимально открытым психологу, т.к.
                  чем более открытым и заинтересованным будет клиент, тем
                  больших результатов можно получить.
                </p>
              </div>
            </div>
          </li>
          <li className="faq__accordion-item" data-accordion="element">
            <a href="/" className="faq__accordion-link" data-accordion="button">
              Как подготовиться к сессии?
            </a>
            <div className="faq__accordion-content" data-accordion="content">
              <div className="faq__accordion-inner">
                <p>
                  Особых приготовлений ненужно, если клиент боится что-то
                  забыть, может записать. А так Вам понадобится только
                  компьютер, подключение к интернету и уединенное место.
                </p>
              </div>
            </div>
          </li>
          <li className="faq__accordion-item" data-accordion="element">
            <a href="/" className="faq__accordion-link" data-accordion="button">
              На какой результат мне рассчитывать?
            </a>
            <div className="faq__accordion-content" data-accordion="content">
              <div className="faq__accordion-inner">
                <p>
                  Результат будет зависеть от нескольких факторов, от желания
                  самого клиента что-то изменить, от профессионализма психолога
                  и от их совместной работы. Также важным аспектом является
                  окружение клиента, оно может как мотивировать, так и
                  демотивировать его. Следовательно, критерии оценки
                  психологического консультирования будут проходить с трех
                  сторон: со стороны клиента, со стороны психолога-консультанта
                  и со стороны окружения клиента.
                </p>
                <p>
                  По итогу консультирования, клиент может прийти к нескольким
                  вариантам развития своей ситуации: оставить все как есть, и
                  ничего не менять, изменить ситуацию, измениться самому, выйти
                  из ситуации.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Faq;
