import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

import { getDictionaries } from '../../../1_shared/api/dictionary';
import useSWRWithCache from '../../../1_shared/api/lib/useSWRWithCache';
import { IOption } from '../../../1_shared/config/interfaces/IOption';
import { DatePicker, Select, Typography } from '../../../1_shared/ui';

import styles from './SpecInfoAbout.module.scss';

const SpecInfoAbout = () => {
  const { control } = useFormContext();

  const { data: workWithOptions } = useSWRWithCache<IOption[]>(
    '/spec/work-withs',
    getDictionaries,
  );

  const { data: keyThemesOptions } = useSWRWithCache<IOption[]>(
    '/spec/key-themes',
    getDictionaries,
  );

  const { data: specialitiesOptions } = useSWRWithCache<IOption[]>(
    '/spec/specialities',
    getDictionaries,
  );

  const { data: dictionaryMethods } = useSWRWithCache<IOption[]>(
    '/spec/work-methods',
    getDictionaries,
  );

  const workWithMemo = useMemo(
    () =>
      workWithOptions?.map((el: IOption) => ({
        label: el.label,
        value: el.id,
      })),
    [workWithOptions],
  );

  const workMethodsMemo = useMemo(
    () =>
      dictionaryMethods?.map((el: IOption) => ({
        label: el.label,
        value: el.id,
      })),
    [dictionaryMethods],
  );

  const specMemo = useMemo(
    () =>
      specialitiesOptions?.map((el: IOption) => ({
        label: el.label,
        value: el.id,
      })),
    [specialitiesOptions],
  );

  const keyThemasMemo = useMemo(
    () =>
      keyThemesOptions?.map((el: IOption) => ({
        label: el.label,
        value: el.id,
      })),
    [keyThemesOptions],
  );

  return (
    <div className={styles.root}>
      <Typography type="title">Информация о вас как о специалисте</Typography>
      <div className={styles.row}>
        <Typography type="description">Дата начала работы</Typography>
        <Controller
          name="data.startWorkDate"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              value={value ? dayjs(value) : null}
              onChange={value =>
                onChange(value ? dayjs(value).format('YYYY-MM-DD') : null)
              }
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">Работаете с</Typography>
        <Controller
          control={control}
          name="data.workWiths"
          render={({ field }) => (
            <Select
              allowClear
              mode="multiple"
              options={workWithMemo}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">Работаете по методам</Typography>
        <Controller
          control={control}
          name="data.workMethods"
          render={({ field }) => (
            <Select
              allowClear
              maxTagCount="responsive"
              mode="multiple"
              options={workMethodsMemo}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">Ключевые темы</Typography>
        <Controller
          control={control}
          name="data.keyThemes"
          render={({ field }) => (
            <Select
              allowClear
              mode="multiple"
              options={keyThemasMemo}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">Специализация</Typography>
        <Controller
          control={control}
          name="data.specialities"
          render={({ field }) => (
            <Select allowClear mode="multiple" options={specMemo} {...field} />
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">О вас</Typography>
        <Controller
          control={control}
          name="data.about"
          render={({ field }) => <TextArea {...field} />}
        />
      </div>
    </div>
  );
};

export default SpecInfoAbout;
