import React from 'react';
import { Flex, TimePicker } from 'antd';

import { Label } from '../../Label';

import { ICustomTimePickerProps } from './ICustomTimePickerProps';

export const CustomTimePicker: React.ForwardRefExoticComponent<ICustomTimePickerProps> =
  React.forwardRef(({ name = '', label = '', ...props }, ref) => (
    <Flex vertical>
      <Label name={name} label={label} />
      <TimePicker format="HH:mm" ref={ref} id={name} {...props} />
    </Flex>
  ));
