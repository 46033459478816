import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { IOption } from '1_shared/config/interfaces/IOption';
import { IPagination } from '1_shared/config/interfaces/IPagination';
import { ISlicedSpecialistData } from '1_shared/config/interfaces/ISpecialistData';

import { IUseGetSpecListOutput } from './interfaces/IUseGetSpecListOutput';
import { getSpecialistList } from './specService';

const defaultPagination = {
  pageNumber: 0,
  pageSize: 25,
};

const useGetSpecialistList = (): IUseGetSpecListOutput => {
  const { getValues } = useFormContext<ISpecialistListFilters>();
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [specList, setSpecList] = useState<ISlicedSpecialistData>({
    first: [],
    second: [],
    third: [],
    fourth: [],
  });
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<ISpecialistListFilters | {}>(
    getValues(),
  );
  const { data: dictionary } = useSWRWithCache<IOption[]>(
    '/spec/specialities',
    getDictionaries,
  );

  const {
    data: specListData,
    trigger,
    isMutating: isLoading,
  } = useSWRMutation('/spec/specialist/all/filtered', getSpecialistList);

  useEffect(() => {
    setPagination(defaultPagination);
  }, [filters]);

  useEffect(() => {
    (async () => {
      await trigger({
        filter: filters,
        pageRequest: pagination,
      });
    })();
  }, [filters, pagination]);

  const specTemp = useMemo(
    () =>
      specListData?.content.map(el => ({
        ...el,
        specialities: dictionary
          ? el.specialities.map(
              spec =>
                dictionary?.find(
                  (option: IOption<string>) => option.value === spec,
                )?.label ?? '',
            )
          : [],
      })),
    [specListData, dictionary],
  );

  const paginationTemp = useMemo(() => specListData?.pageable, [specListData]);

  useEffect(() => {
    if (specTemp) {
      setSpecList(prev => ({
        ...prev,
        fourth: [...(prev?.fourth || []), ...specTemp],
      }));
    }
    if (pagination.pageNumber === 0 && specTemp) {
      setSpecList({
        first: [...specTemp.slice(0, 8)],
        second: [...specTemp.slice(8, 15)],
        third: [...specTemp.slice(15, 20)],
        fourth: [...specTemp.slice(20, 25)],
      });
    }
  }, [specTemp]);

  useEffect(() => {
    if (paginationTemp) {
      setTotal(paginationTemp.total);
    }
  }, [paginationTemp]);

  const viewMore = (): void => {
    setPagination(prev => ({
      ...prev,
      pageNumber: prev.pageNumber + 1,
    }));
  };
  const totalShowedSpecs =
    Number(specList?.first?.length || 0) +
    Number(specList?.second?.length || 0) +
    Number(specList?.third?.length || 0) +
    Number(specList?.fourth?.length || 0);
  return { specList, isLoading, viewMore, setFilters, total, totalShowedSpecs };
};

export default useGetSpecialistList;
