import {useEffect} from "react";
import ReactDOM from 'react-dom/client';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router";
import * as Sentry from "@sentry/react";

import App from './app/App';

Sentry.init({
  dsn: "https://9e706ee4267120345eec3c231fa6fea9@sentry.dtechs.dev/9",

  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ["localhost", "https://dot.dtechs.dev"],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);
