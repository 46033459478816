import React, { PropsWithChildren } from 'react';

import { AuthContext } from '../lib/context/AuthContext';
import { useAuth } from '../lib/hooks/useAuth';

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
