import { Checkbox } from 'antd';

import { ICustomCheckboxProps } from './interfaces/ICustomCheckboxProps';

import './Circle.scss';

const CheckboxCircle = (props: ICustomCheckboxProps) => (
  <div className="root">
    <Checkbox {...props} />
  </div>
);

export default CheckboxCircle;
