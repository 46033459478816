import dayjs from 'dayjs';

import { Button } from '1_shared/ui';

import { IAddEventBtnsProps } from './interfaces/IAddEventBtnsProps';

import styles from './AddEventButton.module.scss';

const AddEventButton = ({
  onClick,
  disabled = false,
  ...props
}: any & IAddEventBtnsProps) => (
  <div className={styles.root}>
    {props.resource !== undefined && (
      <>
        {props.value.getTime() >= dayjs().toDate().getTime() && !disabled && (
          <div className={styles.btn}>
            <Button type="secondary" onClick={onClick}>
              ДОБАВИТЬ
            </Button>
          </div>
        )}
        {props.value.getTime() < dayjs().toDate().getTime() && (
          <div className="rbc-time-slot-locked" />
        )}
      </>
    )}

    {props.resource === undefined && props.children}
  </div>
);
export default AddEventButton;
