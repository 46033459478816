import {
  createElement,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';
import cn from 'classnames';

import { ITypographyProps } from '../types/types';

import styles from './CustomTypography.module.scss';

export const CustomTypography = forwardRef<
  HTMLElement,
  PropsWithChildren<ITypographyProps> &
    DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>(
  (
    {
      children,
      component = 'span',
      type = 'p',
      className: currentClassName,
      ...props
    },
    ref,
  ) => {
    const className = cn(styles.typography, currentClassName, {
      [styles[type]]: type,
    });
    return createElement(component, { className, ref, ...props }, children);
  },
);

export default CustomTypography;
