import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from 'antd';
import dayjs from 'dayjs';

import { DatePicker, Input, Typography, Upload } from '1_shared/ui';

import { ESex } from '../../../1_shared/config/interfaces/ESex';

import styles from './SpecInfoForm.module.scss';

const SpecInfoForm = () => {
  const { control } = useFormContext();
  return (
    <div className={styles.root}>
      <Typography type="title">Основная информация</Typography>
      <div className={styles.imagesWrapp}>
        <div className={styles.uploadWrapp}>
          <Typography type="description">Аватар</Typography>
          <Controller
            control={control}
            name="avatar"
            render={({ field: { value, onChange } }) => (
              <Upload
                maxCount={1}
                accept=".png, .jpg, .jpeg, .webp"
                onChange={info => {
                  if (info.file.status === 'removed') {
                    onChange(null);
                  } else {
                    onChange(info.file);
                  }
                }}
                fileList={value ? [value] : []}
                customRequest={({ onSuccess }) => onSuccess && onSuccess('ok')}
                listType="picture-circle"
              />
            )}
          />
        </div>
        <div className={styles.uploadWrapp}>
          <Typography type="description">Медиа файлы</Typography>
          <Controller
            control={control}
            name="mediaFiles"
            render={({ field: { value, onChange } }) => (
              <Upload
                accept=".png, .jpg, .jpeg, .webp"
                onChange={info => {
                  onChange(info.fileList);
                }}
                fileList={value || []}
                customRequest={({ onSuccess }) => onSuccess && onSuccess('ok')}
                listType="picture-card"
              />
            )}
          />
        </div>
      </div>
      <div className={styles.row}>
        <Typography type="description">ФИО</Typography>
        <div className={styles.fioWrap}>
          <Controller
            name="data.userInfo.firstName"
            control={control}
            render={({ field }) => <Input {...field} placeholder="Фамилия" />}
          />
          <Controller
            name="data.userInfo.surname"
            control={control}
            render={({ field }) => <Input {...field} placeholder="Имя" />}
          />
          <Controller
            name="data.userInfo.patronymic"
            control={control}
            render={({ field }) => <Input {...field} placeholder="Отчество" />}
          />
        </div>
      </div>
      <div className={styles.row}>
        <Typography type="description">Почта</Typography>
        <Controller
          name="data.userInfo.email"
          control={control}
          render={({ field }) => <Input {...field} placeholder="e-mail" />}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">Пол</Typography>
        <Controller
          name="data.userInfo.sex"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Radio.Group
              value={value}
              onChange={onChange}
              className={styles.sexGroup}
            >
              <Radio value={ESex.Male}>Мужской</Radio>
              <Radio value={ESex.Female}>Женский</Radio>
            </Radio.Group>
          )}
        />
      </div>
      <div className={styles.row}>
        <Typography type="description">Дата рождения</Typography>
        <Controller
          name="data.userInfo.birthday"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              value={value ? dayjs(value) : null}
              onChange={value =>
                onChange(value ? dayjs(value).format('YYYY-MM-DD') : null)
              }
            />
          )}
        />
      </div>
    </div>
  );
};

export default SpecInfoForm;
