import { useMemo, useState } from 'react';
import cn from 'classnames';

import { ITabsProps } from './interfaces/ITabsProps';

import styles from './Tabs.module.scss';

const Tabs = ({
  activeTab,
  items,
  defaultActiveTabIndex,
  classNameTabs = '',
  disabledToggle = false,
}: ITabsProps) => {
  const [active, setActive] = useState<string | number>(
    activeTab || defaultActiveTabIndex,
  );

  const onToggleTab = (tabIndex: string | number) => {
    if (!disabledToggle) {
      setActive(tabIndex);
    }
  };

  const activeTabIndex = useMemo(
    () => (disabledToggle ? activeTab : active),
    [active, disabledToggle, activeTab],
  );

  const activeContent = useMemo(
    () => items?.find(item => item.key === activeTabIndex),
    [activeTabIndex, items],
  );

  return (
    <section className={styles.root}>
      <div className={cn(styles.tabs_controls, classNameTabs)}>
        {items?.map(item => (
          <button
            key={item.key}
            className={cn(styles.tabs_control, {
              [styles.tabs_active]: activeTabIndex === item.key,
            })}
            type="button"
            onClick={() => onToggleTab(item.key)}
          >
            {item.label}
          </button>
        ))}
      </div>
      {activeContent?.children}
    </section>
  );
};

export default Tabs;
