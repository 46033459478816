import React from 'react';
import { DatePicker, Flex } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT, DATE_FORMAT_SERVER } from '1_shared/constants/dateFormat';

import { Label } from '../../Label';

import { ICustomDatePickerProps } from './ICustomDatePickerProps';

import styles from './CustomDatePicker.module.scss';

const CustomDatePicker: React.ForwardRefExoticComponent<ICustomDatePickerProps> =
  React.forwardRef(
    (
      {
        picker = 'date',
        format = DATE_FORMAT,
        name = '',
        label = '',
        outputFormat = DATE_FORMAT_SERVER,
        onChange,
        ...props
      },
      ref,
    ) => {
      const handleChange = (
        value: Dayjs | null,
        dateString: string | string[],
      ) => {
        if (!value) {
          onChange?.(null, dateString);
          return;
        }

        const output = outputFormat
          ? dayjs(value).format(outputFormat)
          : dayjs(value);

        onChange?.(output as Dayjs, dateString);
      };

      return (
        <Flex vertical className={styles.datePicker}>
          <Label name={name} label={label} />
          <DatePicker
            onChange={handleChange}
            picker={picker}
            format={format}
            {...props}
            ref={ref}
            id={name}
          />
        </Flex>
      );
    },
  );

export default CustomDatePicker;
