import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoginStatus } from '../../../1_shared/config/interfaces/LoginStatus';
import { isPhoneField } from '../lib/checkPhone';
import { ILoginForm } from '../ui/interfaces/ILoginForm';

import { IHookSubmitOutput } from './intreface/submit/IHookSubmitOutput';
import useLogin from './useLogin';
import useRegister from './useRegister';

const useSubmit = (): IHookSubmitOutput => {
  const { jwt, email } = useParams();
  const isPasswordPage = Boolean(jwt && email);
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.None);
  const { login, errorTimerMessage, setErrorTimerMessage } = useLogin({
    setLoginStatus,
    status: loginStatus,
  });
  const {
    register,
    errorMessage: regErrorMessage,
    setErrorMessage: setRegErrorMessage,
  } = useRegister({
    status: loginStatus,
    setLoginStatus,
    isPasswordPage,
    jwt: String(jwt),
    email: String(email),
  });

  const resetStates = (onChange: (value: string) => void, value: string) => {
    if (loginStatus !== LoginStatus.None) setLoginStatus(LoginStatus.None);
    if (regErrorMessage !== null) setRegErrorMessage(null);
    if (errorTimerMessage !== null) setErrorTimerMessage(null);
    onChange(value);
  };

  const onSubmit = async (data: ILoginForm) => {
    const tempData: ILoginForm = {
      ...data,
      login: isPhoneField(data.login)
        ? data.login.replace('_', '')
        : data.login,
    };
    if (isLogin && !isPasswordPage) {
      await login(tempData);
    } else {
      await register(tempData);
    }
  };

  return {
    setIsLogin,
    onSubmit,
    resetStates,
    loginStatus,
    isLogin,
    isPasswordPage,
    loginError: errorTimerMessage,
    regErrorMessage,
  };
};

export default useSubmit;
