import React from 'react';
import { Breadcrumb } from 'antd';

import { IBreadcrumbsProps } from '../types/types';

import styles from './Breadcrumbs.module.scss';

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
  items,
  separator = '•',
}) => (
  <Breadcrumb
    className={styles.breadcrumbs}
    items={items}
    separator={separator}
  />
);

export default Breadcrumbs;
