import { useNavigate } from 'react-router-dom';

import Close from '1_shared/assets/pictures/closeGray.svg';
import { LoginForm } from '4_widgets';

import { RoutePath } from '../../../1_shared/config/routes';

import styles from './LoginPage.module.scss';

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.root}>
      <div className={styles.gridForm}>
        <LoginForm />
        <div className={styles.close}>
          <button
            type="button"
            className={styles.btn}
            onClick={() => navigate(RoutePath.MAIN)}
          >
            <img className={styles.imgClose} src={Close} alt="clsoe" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
