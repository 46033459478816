import { specApiInstance } from '../../../../1_shared/api/apiInstance';
import { IFirstFormInput } from '../interfaces/IFirstFormInput';

export const fillForm = async (
  url: string,
  { arg }: { arg: IFirstFormInput },
) =>
  await specApiInstance.post(
    url,
    {
      ...arg,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
