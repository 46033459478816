import * as yup from 'yup';

import { IApplicationForm } from '../ui/interface/IApplicationForm';
// TODO add regexp
// const phoneRegExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;

export const schema = yup.object<IApplicationForm>().shape({
  nickname: yup.string().required(),
  slotId: yup.string().required(),
  email: yup.string().email(),
  phone: yup.string().required(),
  code: yup.string(),
  promoCode: yup.string(),
});
