import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { Button, ChipsSelected, Typography } from '1_shared/ui';

import { getDictionaries } from '../../../1_shared/api/dictionary';
import useSWRWithCache from '../../../1_shared/api/lib/useSWRWithCache';
import { IOption } from '../../../1_shared/config/interfaces/IOption';

import { IKeyThemesProps } from './interfaces/IKeyThemesProps';

import styles from './KeyThemes.module.scss';

const KeyThemes = ({ onChange }: IKeyThemesProps): ReactElement => {
  const { control, handleSubmit, setValue } =
    useFormContext<ISpecialistListFilters>();

  const { data: keyThemesOptions, isLoading } = useSWRWithCache<IOption[]>(
    '/spec/key-themes',
    getDictionaries,
  );

  const handleFormSubmit = (data: ISpecialistListFilters) => {
    const filters = {
      filter: { ...data },
    };
    onChange(filters);
  };

  const handleFieldChange = () => {
    handleSubmit(handleFormSubmit)();
  };

  const deleteThemas = (
    keyThemas: string[],
    value: string,
    onChange: (value: string[]) => void,
  ) => {
    const tempArr = keyThemas.filter(el => el !== value);
    onChange(tempArr);
    setValue('keyThemes', tempArr);
  };

  if (isLoading) {
    return <Spin indicator={<LoadingOutlined />} />;
  }
  return (
    <div className={styles.root}>
      <Typography type="title">Не смогли выбрать?</Typography>
      <Typography className={styles.description}>
        Ответьте на несколько вопросов и наши менеджеры помогут вам с подбором подходящего специалиста
      </Typography>
      <div className={styles.btnWrapp}>
        <Button
          type="primary"
          className="btn btn--violet"
          style={{ height: 'auto' }}
          href="https://t.me/TestUZClientBot"
          target="_blank"
        >
          СВЯЗАТЬСЯ С МЕНЕДЖЕРОМ
        </Button>
      </div>
      <Controller
        control={control}
        name="keyThemes"
        render={({ field: { value, onChange } }) => {
          const rusThemas: IOption[] = value?.map(el =>
            keyThemesOptions?.find((options: IOption) => options.value === el),
          );
          return (
            <div className={styles.chipsBlock}>
              {rusThemas?.map((thema: IOption) => (
                <ChipsSelected
                  key={thema.value}
                  value={thema.label}
                  onDelete={() => {
                    deleteThemas(value, thema.value, onChange);
                    handleFieldChange();
                  }}
                />
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};

export default KeyThemes;
