import cn from 'classnames';

import { Typography } from '1_shared/ui';

import { ISpecDescriptionProps } from './interfaces/ISpecSpecializtionProps';

import styles from './SpecDescription.module.scss';

const SpecDescription = ({
  description,
  className = '',
  dark = false,
}: ISpecDescriptionProps) => (
  <div className={cn(styles.root, { [className]: className !== '' })}>
    <Typography type="description">ОПИСАНИЕ</Typography>
    <div>
      <Typography key={description} className={cn({ [styles.darkText]: dark })}>
        {description}
      </Typography>
    </div>
  </div>
);

export default SpecDescription;
