import { createContext } from 'react';
import { MessageInstance } from 'antd/es/message/interface';

const NotificationContext = createContext<{
  messageApi: MessageInstance | null;
}>({
  messageApi: null,
});

export default NotificationContext;
