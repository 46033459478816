import { Radio, RadioProps } from 'antd';
import cn from 'classnames';

import styles from './CustomRadio.module.scss';

export const CustomRadio = ({ className, ...other }: RadioProps) => (
  <Radio
    {...other}
    className={cn(styles.root, { [String(className)]: className })}
  />
);
