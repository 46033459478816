
import 'app/styles/global/global.scss';
import './meetings.scss';

const MeetingsSingle2 = () => (
  <section className="meetings meetings--single">
    <div className="container meetings__container">
      <div className="meetings__heading">
        <h2 className="meetings__title section-title">
          Как проходят <span>индивидуальные</span> встречи
        </h2>
        <p className="meetings__subtitle">
          Сессии с психологом максимально комфортны
        </p>
      </div>
      <div className="meetings__wrapper">
        <div className="meetings__inner">
          <div className="meetings__item">
            <h3>
              <b>Индивидуальный подбор специалиста</b>
            </h3>
            <p>
              Короткий опрос поможет найти специалистов, которые успешно
              работают с вашим запросом и готовы оказать помощь на высоком
              уровне
            </p>
            <div className="meetings__circle" />
          </div>
          <div className="meetings__item">
            <h3>
              <b>
                Конфиденциально <br />и анонимно!
              </b>
            </h3>
            <p>
              Мы позаботились о том, чтобы вы чувствовали себя в полной
              безопасности и могли свободно исследовать себя: специалисты
              работают в строгом соответствии с этическим кодексом, а мы
              гарантируем полную конфиденциальность вашей личной информации
            </p>
            <div className="meetings__circle" />
          </div>
          <div className="meetings__item">
            <h3>
              <b>
                Онлайн-сессии <br /> в личном профиле
              </b>
            </h3>
            <p>
              Все встречи со специалистом проходят онлайн на нашей платформе. Мы
              позаботились о вашем удобстве и конфиденциальности
            </p>
            <div className="meetings__circle" />
          </div>
          <div className="meetings__item">
            <h3>
              <b>
                Контроль расписания <br /> сессий
              </b>
            </h3>
            <p>
              Выбирайте удобное время для занятий с психологом. Отслеживайте все
              сессии в календаре с напоминаниями
            </p>
            <div className="meetings__circle" />
          </div>
          <div className="meetings__item">
            <h3>
              <b>Поддержка службы заботы</b>
            </h3>
            <p>
              Мы всегда рядом и готовы ответить на любые вопросы в чате и по
              телефону
            </p>
            <div className="meetings__circle" />
          </div>
          <div className="meetings__item">
            <h3>
              <b>Разнообразие форматов</b>
            </h3>
            <p>
              Встречайтесь со специалистом не только онлайн но и очно. Мы предоставляем комфортные пространства для очных консультаций на базе психологического центра.
            </p>
            <div className="meetings__circle" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MeetingsSingle2;
