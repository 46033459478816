import { useMemo, useState } from 'react';
import { Radio } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { sortBy, take } from 'lodash';

import { ISlot } from '1_shared/config/interfaces/ISlot';
import { SlotType } from '1_shared/config/interfaces/SlotType';
import { MAX_SLOTS } from '1_shared/constants/variables';
import { getClosestSlots } from '1_shared/lib/helpers/getClosestSlots';
import { Button, RadioButton, Typography } from '1_shared/ui';
import { PopupSlots } from '3_features';

import { checkDateNow } from '../../../1_shared/lib/helpers/checkDateNow';

import { ISlotsProps } from './interfaces/ISlotsProps';

import styles from './Slots.module.scss';

const Slots = ({
  slots,
  value,
  onChange,
  spec,
  className = '',
  dark = false,
}: ISlotsProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const slotsMemo = useMemo(
    () =>
      getClosestSlots(
        slots.length <= MAX_SLOTS ? slots : take(slots, MAX_SLOTS),
      ),
    [slots],
  );

  const onClose = () => {
    setOpen(prev => !prev);
  };

  return (
    <div className={cn(styles.slotsBlock, { [className]: className !== '' })}>
      <Typography type="description">БЛИЖАЙШИЕ СЛОТЫ</Typography>
      {!slots?.length ? (
        'Все слоты заняты'
      ) : (
        <>
          <Radio.Group
            className={styles.slotsWrapper}
            value={value?.slotId}
            buttonStyle="solid"
            onChange={e => {
              console.log(slots.find(el => el.slotId === e.target.value));
              onChange(slots.find(el => el.slotId === e.target.value));
            }}
          >
            <div className={styles.slots}>
              {Object.keys(slotsMemo).map(date => (
                <div key={date} className={styles.dateBlock}>
                  <div className={styles.listSlots}>
                    {slotsMemo[date].map((slot: ISlot) => (
                      <RadioButton
                        key={slot?.slotId}
                        value={slot.slotId}
                        className={cn({
                          [styles.superVision]:
                            slot.kind === SlotType.Supervision,
                          [styles.darkSlot]:
                            slot.kind !== SlotType.Supervision && dark,
                        })}
                      >
                        {dayjs(slot.start).format('HH:mm')}
                      </RadioButton>
                    ))}
                  </div>
                  <Typography type="description">
                    {checkDateNow(dayjs(date).format('D.MM'), 'D.MM')}
                  </Typography>
                </div>
              ))}
              <Button
                className={cn({ [styles.darkBtn]: dark })}
                type="secondary"
                onClick={onClose}
              >
                ВСЕ СЛОТЫ
              </Button>
            </div>
          </Radio.Group>
          <PopupSlots
            spec={spec}
            slots={sortBy(slots, 'start')}
            open={open}
            onClose={onClose}
            value={value}
            onChange={onChange}
          />
        </>
      )}
    </div>
  );
};

export default Slots;
