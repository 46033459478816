import { TabsProps } from 'antd';

import { SpecInfoAbout, SpecInfoForm } from '../../../3_features';

export const tabItems: TabsProps['items'] = [
  {
    key: '1',
    label: 'Основная информация',
    children: <SpecInfoForm />,
  },
  {
    key: '2',
    label: 'Информация как о специалисте',
    children: <SpecInfoAbout />,
  },
];
