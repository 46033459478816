/* eslint-disable react/no-unstable-nested-components */
import { useCallback, useMemo, useState } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import { ReactComponent as Arrow } from '1_shared/assets/pictures/ArrowCalendar.svg';
import ScheduleEvents from '2_entities/ScheduleEvents/ui/ScheduleEvents';
import ScheduleHeader from '2_entities/ScheduleHeader/ui/ScheduleHeader';

import { Button, Select, Typography } from '../../../1_shared/ui';
import AddEventButton from '../../AddEventButton/ui/AddEventButton';
import ModalScheduleEvent from '../../ModalScheduleEvent/ui/ModalScheduleEvent';
import ModalScheduleWeek from '../../ModalScheduleWeek/ui/ModalScheduleWeek';
import { IEventsSchedule } from '../module/interfaces/IEventsSchedule';
import useGetSchedule from '../module/useGetSchedule';

import styles from './Schedule.module.scss';

import 'react-big-calendar/lib/css/react-big-calendar.css';

dayjs.extend(weekday);
dayjs.locale('ru');

const localizer = dayjsLocalizer(dayjs);

const today = new Date();

const Schedule = () => {
  const [choosenValue, setChoosenValue] = useState<Date | null>(null);
  
  const [open, setOpen] = useState<{
    openSchedule: boolean;
    openEvent: boolean;
  }>({
    openSchedule: false,
    openEvent: false,
  });

  const {
    activeWeek,
    schedule,
    isLoading,
    fetchEvents,
    deleteEvent,
    onPrev,
    onNext,
    selectedSpecialist,
    setSelectedSpecialist,
    specialists,
  } = useGetSchedule();

  const onSwitchModal = () => {
    setOpen(prev => ({ ...prev, openSchedule: !prev.openSchedule }));
  };

  const onSwitchModalEvent = (value?: Date) => {
    setOpen(prev => ({ ...prev, openEvent: !prev.openEvent }));
    if (value) {
      setChoosenValue(value);
    }
  };

  const disableBtnOnEvent = useCallback(
    (value: Date) =>
      schedule?.some(
        (event: IEventsSchedule) =>
          (dayjs(event.start).subtract(30, 'minutes').toDate().getTime() <=
            value.getTime() &&
            event.end.getTime() > value.getTime()) ||
          (dayjs(event.start).subtract(1, 'hours').toDate().getTime() <
            value.getTime() &&
            event.end.getTime() > value.getTime()) ||
          (dayjs(value).hour() >= 22 && dayjs(value).minute() >= 30),
      ),
    [schedule],
  );

  const disableHourAndHalf = useMemo(
    () =>
      schedule.some(
        event =>
          dayjs(choosenValue).add(1, 'hours').toDate().getTime() ===
          event.start.getTime(),
      ),
    [schedule, choosenValue],
  );

  const handleSpecialistChange = (value: string) => {
    setSelectedSpecialist(value);
  };
  const specialistOptions = useMemo(() => 
    specialists.map(spec => ({
      value: spec.id,
      label: `${spec.firstName} ${spec.lastName}`,
    })),
    [specialists]
  );
  return (
    <section className={styles.root}>
            <div className={styles.header}>
        <Typography type="title">Расписание специалиста
        <Select
          placeholder='Выберите специалиста'
          onChange={handleSpecialistChange}
          options={specialistOptions}
        />
        </Typography>
        <div className={styles.paginationBlock}>
          <Button type="secondary" onClick={onPrev} className={styles.btn}>
            <Arrow />
          </Button>
          <Typography>{`${dayjs(activeWeek).format('D')}-${dayjs(activeWeek).add(6, 'days').format('D MMMM')}`}</Typography>
          <Button type="secondary" onClick={onNext} className={styles.btn}>
            <Arrow className={styles.arrowNext} />
          </Button>
        </div>
      </div>

      {selectedSpecialist && !isLoading ? (
        <Calendar
          localizer={localizer}
          events={schedule}
          defaultDate={activeWeek}
          defaultView="week"
          components={{
            header: props => <ScheduleHeader {...props} />,
            eventWrapper: props => (
              <ScheduleEvents {...props} deleteEvent={deleteEvent} />
            ),
            timeSlotWrapper: (props: any) => (
              <AddEventButton
                {...props}
                disabled={disableBtnOnEvent(props.value)}
                onClick={() => {
                  onSwitchModalEvent((props as any).value);
                }}
              />
            ),
          }}
          min={
            new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
          }
          max={
            new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23)
          }
          timeslots={2}
          step={30}
          toolbar={false}
          enableAutoScroll
          className={styles.calandar}
          startAccessor="start"
          endAccessor="end"
        />
      ) : (
        <div className={styles.loaderWrapper}>
          {selectedSpecialist ? <Spin /> : <Typography>Выберите специалиста</Typography>}
          </div>
      )}

      <Button type="secondary" onClick={onSwitchModal}>
        РЕДАКТИРОВАТЬ РАБОЧЕЕ ВРЕМЯ
      </Button>
      {selectedSpecialist && (
      <ModalScheduleWeek
        open={open.openSchedule}
        onClose={onSwitchModal}
        specialist={selectedSpecialist}
        addEvents={fetchEvents}
        activeWeek={activeWeek}
      />
      )}
      {choosenValue && selectedSpecialist && (
        <ModalScheduleEvent
          specialist={selectedSpecialist}
          open={open.openEvent}
          addEvent={fetchEvents}
          disableHourAndHalf={disableHourAndHalf}
          onClose={onSwitchModalEvent}
          chooseEvent={choosenValue}
        />
      )}
    </section>
  );
};

export default Schedule;
